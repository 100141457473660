import React,{ useState,forwardRef, useEffect, useLayoutEffect, useRef } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox,vBox} from "gx-web-ui/utils/flex";
import PropertyCard from '@src/components/property/PropertyCard'
import ProjectCard from '@src/components/property/ProjectCard'

import { useTheme, makeStyles,fade,withStyles } from '@material-ui/core/styles';

//import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CustomTextPairDisplay from '../common/CustomTextPairDisplay'
import TextField from '@material-ui/core/TextField'

import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
//import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch'

import { ReactComponent as Edit } from '@src/assets/svgIcons/Edit.svg'
import IconButton from '@material-ui/core/IconButton';

import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider'
import ConfirmIconButton from '../common/ConfirmIconButton'
import {Tabs, List, Typography, Input, Spin, Button, Modal,} from 'antd'
import MButton from '@material-ui/core/Button'
import BlackBtn from '@src/components/common/BlackBtn'
import SidePanelHeader from './SidePanelHeader'
import { format, parseISO } from 'date-fns';
import _ from 'lodash'

import Icon, {ArrowRightOutlined} from '@ant-design/icons';

import { ReactComponent as Add } from '@src/assets/svgIcons/Add_person.svg'
import { ReactComponent as Search } from '@src/assets/svgIcons/Search.svg'
import { ReactComponent as Phone } from '@src/assets/svgIcons/Phone 1.svg'
import { ReactComponent as Message } from '@src/assets/svgIcons/Message a.svg'
import { ReactComponent as ArrowLeft } from '@src/assets/svgIcons/ArrowLeft.svg'
import { ReactComponent as Confirm } from '@src/assets/svgIcons/Confirm.svg'
import { ReactComponent as Location } from '@src/assets/svgIcons/Location.svg'
import { ReactComponent as RingPhone } from '@src/assets/svgIcons/Phone.svg'
import ReactPlayer from 'react-player'

import Masonry from 'react-masonry-component';
import {propertySlugGeneratorFromRaw} from '@src/utils/slug' 

const {Text, Title} = Typography
const { TabPane } = Tabs

const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    }
    
}))

const InputWithBorder = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: 0,
            width: '100%'
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        height : 36,
        padding: '12px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.1)} 0 0 0 0.1rem`,
            borderColor: theme.palette.greyPrimary.main,
        },
    },
}))(InputBase);


const  MyAgentsPanel = (props) => {

    const {appStore, propertyStore, uiStore, closeFunction} = props

    const {loggedInUser} = appStore
    const styles = useStyle()
    const [loading, setLoading] = useState(true)
    const [agents, setAgents] = useState([])
    const [status, setStatus] = useState('showList')
    const agentIdRef = useRef(0)
    const agencyIdRef = useRef(0)
    const agencyAddressRef = useRef('')

    useEffect(() => {
        loadData()
    }, [])


    function onSearch() {
        setStatus('searchAgent')
    }

    function onAddContact() {
        setStatus('searchAgent')
    }

    const parseData = (e) => {
        
        return {
            agentId: e.id,
            name: e[':user'].name,
            avatar: e[':user'].avatar,
            mobile: e[':user'].mobile,
            email: e[':user'].email,
            userId: e[':user'].id,
            agencyLogo: e.delegate !== 1 ? e[':agency']?.avatar : null
        }
    }

    const loadData = async () => {
        setLoading(true)
        const res = await appStore.clientApi.getAgentContacts_()
        //console.log('###agents: ', JSON.stringify(res, null, 4))

        if (res && Array.isArray(res)) {
            setLoading(false)
            setAgents(res.map(parseData))
        }
    }

    const onClickItem = async (item) => {
        //agentIdRef.current = item.agentId
        //setStatus('showAgentDetail')
        console.log(item)
        window.open(`/agent/${item.agentId}`,'_blank')
    }

    const onBack = (reload = false) => {
        setStatus('showList')

        if (reload) {
            loadData()
        }
    }

    const onClose = () => {
        closeFunction()
    }

    const onClickAgency = (agencyId, agencyAddress) => {
        console.log('on click agency,' + agencyId)
        agencyIdRef.current = agencyId
        agencyAddressRef.current = agencyAddress
        setStatus('showAgencyDetail')
    }

    if (loading) {
        return (
            <div style={{...vBox('center', 'center'), width: '100%', height: '100%'}}>
                <Spin />
            </div>
        )
    }

    const isShowList = status === 'showList'
    let contentView
    if (status === 'showList') {
        contentView = <AgentListView {...{onSearch, onAddContact, agents, onClickItem, onClose}} />
    } else if (status === 'searchAgent') {
        contentView = <SearchAgentView onBack={onBack} appStore={appStore} onClickItem={onClickItem} />
    } else if (status === 'showAgentDetail') {
        contentView = <AgentDetailView id={agentIdRef.current} appStore={appStore} propertyStore={propertyStore} uiStore={uiStore} onBack={onBack} agents={agents} onClickAgency={onClickAgency} />
    } else if (status === 'showAgencyDetail') {
        contentView = <AgencyDetailView agencyId={agencyIdRef.current} appStore={appStore} propertyStore={propertyStore} uiStore={uiStore} onBack={() => setStatus('showAgentDetail')} agencyAddress={agencyAddressRef.current} />
    } 

    return(
        <div style={{height : "100%"}}>
            {contentView}
        </div>
    )
}

const SearchAgentView = (props) => {
    const {onBack, appStore, onClickItem} = props
    const inputRef = useRef(null)
    const [agents, setAgents] = useState([])
    const [loading, setLoading] = useState(false)

    useLayoutEffect(() => {
        inputRef.current.focus()
    }, [])

    const parseData = (e) => {
        return {
            agentId: e.id,
            name: e[':user'].name,
            avatar: e[':user'].avatar,
            mobile: e[':user'].mobile,
            email: e[':user'].email,
            agencyLogo: e.delegate !== 1 ? e[':agency']?.avatar : null,
        }
    }

    const onSearch = async (e) => {
        const keyword = inputRef.current.state.value
        //console.log(inputRef.current.state.value)
        setLoading(true)
        const res = await appStore.clientApi.searchAgents_(keyword)

        //console.log(res)

        if (res && Array.isArray(res)) {
            setAgents(res.map(parseData))
        }

        setLoading(false)
    }

    const onTextChange = (event) => {
        //const keyword = inputRef.current.state.value
        ///console.log(keyword)
    }

    const render = () => {
        /*if (loading) {
            return (
                <div style={{...vBox('center', 'center'), width: '100%', height: '100%', marginTop: 160}}>
                    <Spin />
                </div>
            )
        }*/

        return (
            <div>
                <div style={{width : '100%', height : '100%', background : '#ffffff'}}>
                    <div style={{ ...hBox('center') , height : 48}}>
                        {
                            onBack ? 
                            <IconButton disableRipple onClick={onBack} style = {{background : 'transparent',position :'relative', left : 0}}>
                                <ArrowLeft />
                            </IconButton>
                            :
                            <div style= {{width : 48}}></div>
                        }
                        <div style={{ ...hBox('center','center'),width : "100%"}}>
                            <Input bordered={false} placeholder={'Search agent here'} prefix={<Search style={{marginRight: 5}} />}
                            style={{paddingLeft: 0, marginRight: 20}}
                            onPressEnter={onSearch}
                            ref={inputRef}
                            onChange={onTextChange}
                            />
                        </div>
                    </div>
                    <div style={{paddingLeft : 18, paddingRight : 18}}>
                        <Divider /> 
                    </div>
                </div>
                {loading &&
                <div style={{...vBox('center', 'center'), width: '100%', height: '100%', marginTop: 200}}>
                    <Spin />
                </div>
                }
                <div style={{paddingLeft: 15}}>
                    { agents.length > 0 &&
                    <List
                        itemLayout="horizontal"
                        dataSource={agents}
                        renderItem={item => (
                            <List.Item onClick={() => onClickItem(item)}>
                                <AgentCard avatar={item.avatar} name={item.name} email={item.email} agencyLogo={item.agencyLogo} id={item.id} />
                            </List.Item >
                        )}
                    />
                    }
                </div>
            </div>
        )
    }

    return render()
}

const AgentListView = (props) => {
    const {onSearch, onAddContact, agents, onClickItem, onClose} = props

    const searchIcon = (
        <IconButton disableRipple onClick={() => {}} style = {{background : 'transparent', height : 40, width : 40}}>
            <Search />
        </IconButton>
    )

    return (
        <div onScroll={(e) => console.log('scroll')} style={{height : "100%"}}>
            <SidePanelHeader label={'Agents'}
                closeFunction = {onClose} 
                backFunction={null} 
                customizeCloseButton={false}
            />
                <div style={{...hBox('center', 'space-between'), padding: 15,}}>
                    <div style={{...hBox('center', 'flex-start'), borderRadius: 50, alignItems: 'center', paddingLeft: 10, backgroundColor: '#f0f0f0', color: 'black', height: 40, flex: 1}}
                    onClick={onSearch}
                    >
                        <Search />
                        <Text style={{marginLeft: 10}}>Search here</Text>
                    </div>
                    <IconButton disableRipple onClick={onAddContact} style = {{background : 'transparent', height : 50, width : 50}}>
                        <Add />
                    </IconButton>
                </div>
                <div style={{paddingLeft: 15, height : "calc(100% - 108px)", overflow : 'overlay'}} onScroll={(e) => console.log('scroll')}>
                    <List
                        itemLayout="horizontal"
                        dataSource={agents}
                        renderItem={item => (
                            <List.Item onClick={() => onClickItem(item)}>
                                <AgentCard avatar={item.avatar} name={item.name} email={item.email} agencyLogo={item.agencyLogo} id={item.id}/>
                            </List.Item >
                        )}
                    />
                    <IndexList names={agents.map(e => e.name)} onSelectLetter={(e) => {
                        console.log(e)
                    }} />
                </div>
        </div>
    )
}

const AgentCard = (props) => {
    const {id,avatar, name, email, agencyLogo} = props

    const onClickAvatar = ()=>{
        //window.open(`/agent/${id}`,'_blank')
    }
    return (
        <div style={{...hBox('center', 'flex-start'), padding: 10,}}>
            <div style={{position: 'relative'}}>
                <Avatar src={avatar} style={{width: 60, height: 60, cursor:'pointer'}} onClick={onClickAvatar}/>
                {agencyLogo && <Avatar src={agencyLogo} style={{position: 'absolute', width: 28, height: 28, right: 0, bottom: 0, borderColor: 'grey', borderWidth: 1}} />}
            </div>
            <div style={{...vBox('flex-start', 'flex-start'), marginLeft: 10,}}>
                <Text style={{fontWeight: '600'}}>{name}</Text>
                <Text style={{fontSize: 15, color: 'grey'}}>{email}</Text>
            </div>
        </div>
    )
}

const AgentContactCard = (props) => {
    const {avatar, name, email, mobile,id } = props
    const [showPhoneNumber, setShowPhoneNumber] = useState(false)

    return (
        <div style={{width: '100%'}}>
            <AgentCard {...{name, avatar, email,id }} />
            <div style={{...hBox('center', 'center'), margin: 10}}>
                <MButton variant='outlined' fullWidth style={{marginRight: 10}} 
                icon={!showPhoneNumber && <Phone style={{marginRight: 10}} />} onClick={() => setShowPhoneNumber(!showPhoneNumber)} 
                >
                    {showPhoneNumber ? mobile : 
                    <>
                    <Phone height={16} width={16} fill='black' style={{marginRight:8}}/>
                    Call
                    </>
                    }
                </MButton>
                <Button type="primary" style={{color: 'white', backgroundColor: 'black', flex: 1, borderColor: 'black'}} 
                icon={<Icon component={Message} style={{color: 'white', marginRight: 10}} />} onClick={() => console.log('click chat')} >{' Chat'}</Button>
            </div> 
        </div>
    )
}

const AgencyCard = (props) => {
    const {agency, onClickAgency, agencyAddress} = props
    const address = agency[':address']?.fullText || agencyAddress

    return (
        <div style={{...hBox('center', 'flex-start'), padding: 10, margin: 10, border: '1px solid #e0e0e0', borderRadius: 4}}
            onClick={onClickAgency}
        >
            <img src={agency.avatar} style={{width: 60, height: 60,}} />
            <div style={{...vBox('flex-start', 'flex-start'), marginLeft: 10,}}>
                <Text style={{fontWeight: '600', fontSize: 16}}>{agency.name}</Text>
                <div style={{...hBox('center', 'flex-start'),}}>
                    <RingPhone style={{color: 'grey', width: 12, height: 12}} />
                    <Text style={{fontSize: 14, color: 'grey', marginLeft: 10}}>{agency.principalPhone}</Text>
                </div>
                <div style={{...hBox('flex-start', 'flex-start'),}}>
                    <Location style={{marginTop: 3, width: 14, height: 14}} />
                    <Text style={{fontSize: 14, color: 'grey', marginLeft: 10}}>{address}</Text>
                </div>
            </div>
        </div>
    )
}

const AboutView = (props) => {
    const { title, detail, minHeight } = props

    return (
        <div style={{...vBox('flex-start', 'flex-start'), margin: 10}}>
            <Text style={{fontWeight: '600', marginBottom: 10}}>{`About ${title}`}</Text>
            <div dangerouslySetInnerHTML={{__html: detail}} ></div>
        </div>
    )
}

const PropertyListView = (props) => {
    const {properties, uiStore} = props
    const {setCurrentListHoverListingId,checkLogin} = uiStore
    const cardWidth = 368
    const styles = useStyle()
    const masonryOptions = {
        //transitionDuration: 0,
        columnWidth:cardWidth,
        gutter: 16
    };
    const [showFullList, setShowFullList] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)

    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const boxShadow = {boxShadow:`0px 4px 10px 0px ${theme.palette.grey[400]}`}

    const onHoverAnimation = {
        x:-2,
        y:-2,
        boxShadow:`2px 6px 12px 0px ${theme.palette.grey[500]}`
    }

    const onHoverStart = (id)=>(e,info)=>{
        setCurrentListHoverListingId(id)
    }

    const onHoverEnd = ()=>{
        setCurrentListHoverListingId(null)
    }

    const onToggleFavor = (listingId,index)=>(newState)=>{
        /*if(newState===true){
            favorProperty_(listingId)
        }else{
            unfavorProperty_(listingId)
        }
        toggleFavorByIndex(newState,index)*/
    }

    const now = format(new Date(), 'dd/MM/yyyy')
    const onChangeTab = (value) => {
        console.log(`tab value ${value}`)
        setCurrentIndex(value - 1)
    }

    const renderTabBar = (props, DefaultTabBar) => (
        <DefaultTabBar {...props} style={{ zIndex: 1, textColor: 'grey', background: '#fff' }} />
    )

    const renderTabTitle = (index) => {
        const titleStyle = index === currentIndex ? {fontWeight: '600', fontSize: 15} : {fontSize: 15}

        if (index === 0) {
            return <Text style={titleStyle}>For sale</Text>
        } else if (index === 1) {
            return <Text style={titleStyle}>Sold</Text>
        } else if (index === 2) {
            return <Text style={titleStyle}>For rent</Text>
        } else if (index === 3) {
            return <Text style={titleStyle}>Leased</Text>
        }
    }

    const renderTabContent = (index) => {
        const filteredProperties = properties.filter((e) => {
            const isForSale = e.rawData[':listing'].type === 'PROPERTY_SALE'
            const isSoldOrRent = e.rawData.statExchanged === 1
            //console.log(e.rawData[':listing'])

            if (index === 0) {
                return isForSale && !isSoldOrRent
            } else if (index === 1) {
                return isForSale && isSoldOrRent
            } else if (index === 2) {
                return !isForSale && !isSoldOrRent
            } else if (index === 3) {
                return !isForSale && isSoldOrRent
            } 
        })

        return (
            <>
                <Masonry 
                    className={{margin: 16}}
                    options={masonryOptions}
                    >
                    {
                    filteredProperties.filter((e, i) => showFullList ? true : i < 3).map((data,index)=>
                    <div style={{marginBottom: 16, width:cardWidth}} key={data.rawData.id}>
                        {data.processedData.isProject?
                        <ProjectCard 
                            project={data.processedData} 
                            listMode 
                            rootStyle={boxShadow}
                            favored={data.processedData.isSaved}
                            motionProps={{
                                whileHover:onHoverAnimation,
                                onHoverStart:onHoverStart(data.processedData.listingId),
                                onHoverEnd,
                            }}
                            onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')}
                            //onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                        />
                        :
                        <PropertyCard 
                            onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')}
                            property={data.processedData} 
                            rootStyle={boxShadow} 
                            favored={data.processedData.isSaved}
                            //onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                            motionProps={{
                                whileHover:onHoverAnimation,
                                onHoverStart:onHoverStart(data.processedData.listingId),
                                onHoverEnd
                            }}
                        />
                        }
                    </div>
                    )}
                </Masonry>
                {filteredProperties.length > 3 &&
                <div style={{...vBox('center', 'center'), width: '100%',}}>
                    <Button type={'link'} style={{color: 'f76927', height: 40, backgroundColor: 'white', marginTop: 0, fontSize: 16}} 
                        onClick={() => setShowFullList(!showFullList)} >{showFullList ? 'Show less' : 'Show more'}</Button>
                </div>
                }
            </>
        )
    }

    return (
        <div style={{...vBox('flex-start', 'flex-start'), margin: 10, backgroundColor: 'white' }}>
            <Text style={{fontWeight: '600', marginBottom: 10}}>{`Current listings`}</Text>
            <Text style={{color: 'grey', marginBottom: 10}}>{`12 months period to ${now}`}</Text>
            <Tabs defaultActiveKey="1" onChange={onChangeTab} style={{width: '100%'}} size={'small'} renderTabBar={renderTabBar}
            tabBarStyle={{color: 'grey', textDecorationColor: 'grey', textColor: 'grey'}} tabBarGutter={5}>
                <TabPane tab={renderTabTitle(0)} key="1" style={{textColor: 'black'}}>
                    {renderTabContent(0)}
                </TabPane>
                <TabPane tab={renderTabTitle(1)} key="2">
                    {renderTabContent(1)}
                </TabPane>
                <TabPane tab={renderTabTitle(2)} key="3">
                    {renderTabContent(2)}
                </TabPane>
                <TabPane tab={renderTabTitle(3)} key="4">
                    {renderTabContent(3)}
                </TabPane>
            </Tabs>
        </div>
    )
}

const AgentDetailView = (props) => {
    const {id, appStore, propertyStore, uiStore, onBack, agents, onClickAgency} = props
    const [loading, setLoading] = useState(true)
    const [agent, setAgent] = useState(null)
    const [properties, setProperties] = useState([])
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const isAddedRef = useRef(false)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        console.log(`agent id:${id}`)
        setLoading(true)
        const result = await appStore.clientApi.getAgentDetail_(id)

        //console.log('###agent detail: ', JSON.stringify(result, null, 4))
        setAgent(result)
        setLoading(false)

        const res = await appStore.clientApi.getProperties_({agent: id})
        setProperties(propertyStore.generalPropertyStore.processListData(res.items))

        ///console.log(`size is:${properties.length}`)

    }

    const onDeleteAgent = async () => {
        setShowDeleteModal(true)
        //setShowAddModal(true)
    }

    const onAddContact = async () => {
        console.log('on add contact')
        const res = await appStore.clientApi.addContact_(agent.user)
        console.log(JSON.stringify(res, null, 4))
        isAddedRef.current = true
        setShowAddModal(true)
    }

    const onCancelDelete = () => {
        setShowDeleteModal(false)
    }

    const onConfirmDelete = async () => {
        setShowDeleteModal(false)
        console.log('on delete agent')
        const res = await appStore.clientApi.deleteContact_(agent.user)
        console.log(JSON.stringify(res, null, 4))
        onBack(true)
    }

    const onExploreAgents = () => {
        setShowAddModal(false)
        onBack(true)
    }

    const renderModal = () => {
        if (showDeleteModal) {
            return (
                <Modal
                visible={showDeleteModal}
                title=""
                onOk={onConfirmDelete}
                closable={false}
                onCancel={onCancelDelete}
                centered
                footer={null}
                width={300}
                >
                    <div>
                        <Title style={{justifyContent: 'center', alignContent: 'center', alignItems: 'center', fontSize: 15, fontWeight: '600', backgroundColor: 'white'}}>
                            {'Are you sure you want to delete this agent?'}
                        </Title>
                        <div style={{...hBox('center', 'space-between'),  marginTop: 30, alignContent: 'center', border: '1px solid #fff'}}>
                            <Button key="back" style={{width: 110}} onClick={onCancelDelete}> Cancel </Button>
                            <Button key="submit" style={{width: 110, borderColor: '#f76927', color: '#f76927'}} onClick={onConfirmDelete}> Confirm 
                            </Button>
                        </div>
                    </div>
                    
                </Modal>
            )
        } else if (showAddModal) {
            return (
                <Modal
                visible={showAddModal}
                title=""
                onOk={onExploreAgents}
                onCancel={() => {setShowAddModal(false)}}
                centered
                width={300}
                footer={null}
                >
                    <div style={{...vBox('center', 'center')}}>
                        <Icon component={Confirm} style={{color: '#f76927', fontSize: 45, marginTop: 20}} />
                        <Text style={{marginTop: 15}}>{'Added to your contacts'}</Text>
                        <Button key="submit" style={{borderColor: '#f76927', flex: 1, color: '#f76927', width: '100%', marginTop: 15}}
                          onClick={onExploreAgents}
                        >
                        Done
                        </Button>
                    </div>
                </Modal>
            )
        }
    }

    if (loading) {
        return (
            <div style={{...vBox('center', 'center'), width: '100%', height: '100%', marginTop: 200}}>
                <Spin />
            </div>
        )
    }

    const user = agent[':user'];
    const agency = agent[':agency'];
    const person = user[':person'];
    ///const properties = agent[':properties']

    const name = agent.preferredName || person.legalName;
    const avatar = user.avatar
    const email = agent.email
    const agencyLogo = agent.delegate !== 1 ? agency?.avatar : null

    //const date = formatDate(stat.updatedAt || new Date(), 'dd/MM/yyyy');
    let mobile = agent.mobile;
    if (mobile.startsWith('+61')) {
        mobile = '0' + mobile.substr(3);
    }

    const isFriend = agents.findIndex((e) => e.agentId === id) >= 0 || isAddedRef.current

    return (
        <div>
            <div>
                <SidePanelHeader label={'Agent detail'}
                 closeFunction = {isFriend ? null : onAddContact} 
                 backFunction={onBack} 
                 customizeCloseButton={!isFriend}
                 />
            </div>
            <div style={{...vBox('flex-start', 'flex-start'), backgroundColor: '#fff', width: '100%', height: '100%', padding: 10, }}>
                <AgentContactCard {...{name, avatar, email, mobile, id:agent.id}} />
                <AgencyCard agency={agency} onClickAgency={() => onClickAgency(agency.id, agency[':address'].fullText)} />
                <AboutView title={name} detail={agent.about} minHeight={100} />
                <div style={{backgroundColor: 'white', paddingLeft: 10, marginBottom: 10}}>
                    <Button type="primary" 
                    style={{color: 'black', backgroundColor: 'white', flex: 1, borderColor: 'black', marginRight: 60}} 
                    onClick={() => {
                    }} 
                    >
                        {'Sale or lease with me'}
                        <ArrowRightOutlined style={{color: 'black', fontSize: 20, }} />
                    </Button>

                </div>
                {properties.length > 0 &&
                <PropertyListView uiStore={uiStore} properties={properties} />
                }
                {isFriend &&
                <Button type={'link'} style={{color: 'f76927', height: 40, backgroundColor: 'white', marginTop: 30}} 
                 onClick={onDeleteAgent} >{' Delete agent'}</Button>
                }
                <div style={{marginTop: 20, height: 60, backgroundColor: 'white'}} />
            </div>
            {renderModal()}
        </div>
    )
}

const AgencyDetailView = (props) => {
    const {agencyId, appStore, propertyStore, uiStore, onBack, onClickAgency, agencyAddress} = props
    const [loading, setLoading] = useState(true)
    const [agency, setAgency] = useState(null)
    const [properties, setProperties] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        //console.log(`agency id>>>:${agencyId}`)
        setLoading(true)
        const result = await appStore.clientApi.getAgencyDetail_(agencyId)

        //console.log(result)
        setAgency(result)
        setLoading(false)

        const res = await appStore.clientApi.getProperties_({agency: agencyId})
        setProperties(propertyStore.generalPropertyStore.processListData(res.items))

        //console.log(`property size is:${properties.length}`)
    }

    if (loading) {
        return (
            <div style={{...vBox('center', 'center'), width: '100%', height: '100%', marginTop: 160}}>
                <Spin />
            </div>
        )
    }

    const video = agency?.[':resources']?.BANNER?.[0]?.url
    const agents = agency[':agents'].map((e) => {
        return {
            agentId: e.id,
            name: e[':user'].name,
            avatar: e[':user'].avatar,
            mobile: e[':user'].mobile,
            email: e[':user'].email,
            userId: e[':user'].id,
        }
    })

    return (
        <div>
            <div>
                <SidePanelHeader label={'Agency detail'}
                 closeFunction = {null} 
                 backFunction={onBack} 
                 customizeCloseButton={false}
                 />
            </div>
            <div style={{...vBox('flex-start', 'flex-start'), backgroundColor: '#fff', width: '100%', padding: 10, }}>
                <div>
                    {video &&
                    <ReactPlayer 
                        light={true}
                        width={'95%'} 
                        height={'220px'} 
                        url={video} 
                        playing
                        controls
                        style={{margin: 10, marginTop: 0, backgroundColor:'black'}}
                    />
                    }
                </div>
                <AgencyCard agency={agency} onClickAgency={null} agencyAddress={agencyAddress} />
                <AboutView title={agency.name} detail={agency.about} minHeight={100} />
                <AgentCardList agents={agents} agencyName={agency.name} />
                {properties.length > 0 &&
                <PropertyListView uiStore={uiStore} properties={properties} />
                }
                <div style={{height: 100}} />
            </div>
        </div>
    )
}

const AgentCardList = (props) => {
    const {agents, agencyName} = props
    const [showFullList, setShowFullList] = useState(false)

    return (
        <div>
            <Text style={{fontWeight: '600', margin: 10,}}>{`${agencyName} Team`}</Text>
            { agents.filter((e, i) => showFullList ? true : i < 3).map((e) => {
                return (
                    <div
                    style={{...hBox('center', 'flex-start'), padding: 10, margin: 10, border: '1px solid #e0e0e0', borderRadius: 4}}
                    key={e.id}
                    >
                        <AgentContactCard key={e.id} name={e.name} avatar={e.avatar} email={e.email} mobile={e.mobile} id={e.id}/>
                    </div>
                )
              })
            }
            <div style={{...vBox('center', 'center'), width: '100%',}}>
                <Button type={'link'} style={{color: 'f76927', height: 40, backgroundColor: 'white', marginTop: 0, fontSize: 16}} 
                    onClick={() => setShowFullList(!showFullList)} >{showFullList ? 'Show less' : 'Show more'}</Button>
            </div>
        </div>
    )
}

const IndexList = (props) => {
    const {onSelectLetter, names} = props

    const getLetters = (names) => {
        const letters = names.sort().map(e => {
            return e.substr(0, 1).toUpperCase()
        }).filter((e, i, l) => {
            return l.indexOf(e) === i
        })

        letters.push('#')
        return letters
    }

    const letters = getLetters(names)


    return (
        <div style={{...vBox('flex-start', 'flex-start'), position: 'absolute', right: 5, top: 160}}>
            { letters.map((e) => {
                return (
                    <Text onClick={() => onSelectLetter(e)} style={{fontWeight: '400', margin: 2, color: '#d2d2d2', cursor:'pointer'}}>{e}</Text>
                )
              })
            }
        </div>
    )
}

export default compose(inject('appStore','uiStore', 'propertyStore'),observer)(MyAgentsPanel)
