import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import IconButton from '@material-ui/core/IconButton'

import Close from '@material-ui/icons/Close'
import {hBox,vBox} from "gx-web-ui/utils/flex";

import { Grow,Paper, Divider, Button } from '@material-ui/core';
import { Menu } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowLeft } from '@src/assets/svgIcons/ArrowLeft.svg'
import { ReactComponent as Add } from '@src/assets/svgIcons/Add_person.svg'
import Typography from '@material-ui/core/Typography';
import BackButton from '../../components/common/BackButton'

const SidePanelHeader = (props) => {

    const { appStore,uiStore, label, closeFunction, backFunction, customizeCloseButton = false } = props

    //console.log(backFunction, closeFunction)

    return(
        
        <div>
            <div style={{width : '100%', height : '100%', background : '#ffffff'}}>
                <div style={{ ...hBox('center') , height : 68}}>
                    {
                        backFunction ? <BackButton backFunction={backFunction} />
                        :
                        <div style= {{width : 48}}></div>
                    }
                    <div style={{ ...hBox('center','center'),width : "100%"}}>
                        <Typography variant="body1" style={{color: 'grey', fontSize: 17, fontWeight: '500'}} >
                            {label}
                        </Typography>
                    </div>
                    {customizeCloseButton ?                                  
                    <IconButton disableRipple onClick={closeFunction} style = {{background : 'transparent', position :'relative', right : 0}}>
                        <Add />
                    </IconButton>
                    :
                    closeFunction ?
                    <IconButton disableRipple onClick={closeFunction} style = {{background : 'transparent', position :'relative', right : 0}}>
                        <Close />
                    </IconButton>
                    :
                    <div style={{width: 50, height: 50}} />
                    }
                </div>
                
                <div style={{paddingLeft : 18, paddingRight : 18}}>
                    <Divider /> 
                </div>
            </div>
        </div>        
    )
}

export default compose(inject('appStore','uiStore'),observer)(SidePanelHeader)