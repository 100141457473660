
import React, { Component, useState, useEffect } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import { ReactComponent as Edit } from '@src/assets/svgIcons/Edit.svg'
import {hBox} from "gx-web-ui/utils/flex";

const EditIconButton = props=>{
    const {onClick,style,...other} = props
    return  <ButtonBase disableRipple onClick={onClick} style={{...style,'background' : 'transparent', 'border' : '1px solid #c5c5c5',borderRadius : 4, height:42}}>
        <div style={{...hBox('center','center')}}>
            <Edit  style={{ color : 'black', height : 18, width : 18}}/>
            <span style={{'color' : 'black',marginLeft:12}}>Edit</span>
        </div>
                
    </ButtonBase>
}

export default EditIconButton