import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import {hBox,vBox} from "gx-web-ui/utils/flex";
import Edit from '@material-ui/icons/Edit';
import { Upload } from 'antd';
import ImgCrop from "antd-img-crop";
import { makeStyles } from '@material-ui/core/styles';
import hook from "../../gx-web-ui/hook";
import random from 'lodash/random'
// import Icon from '@ant-design/icons';

const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    }
    
}))


const  AvatarEditButton= (props) => {

    const {avatar, editable = true, hasIcon = true,appStore} = props
    const [newAvatar, setNewAvatar] = useState(null)


    const id = appStore.loggedInUser.id
    const ossData = hook.useAsyncCall(() => appStore.client.post('oss-policy-signature', {
        entity:  "user",
        id: id
    }, null, { endpoint: appStore.settings.fndEndpoint }), [id]);

    const imageDir =  `user/${id}/` 

    const { host = '', accessId, policy, signature } = ossData.result || {};
    // console.log('avatar',avatar)
    // console.log('appStore.loggedInUser',appStore.loggedInUser)
    const transformFile = (file) => {
        const suffix = file.name.slice(file.name.lastIndexOf("."));
        const filename = `avatar${suffix}`
        file.url = imageDir + filename;

        console.log('file url:' + file.url)

        return file;
    };

    const getExtraData = (file) => {
        return {
            key: file.url,
            OSSAccessKeyId: accessId,
            policy: policy,
            Signature: signature,
        };
    };

    const onChange = () => async ({ file }) => {
        const { url } = file;
        const value = {};
        value['avatar'] = host + "/" + url;
        console.log('#####on change', host + '/' + url)

        if (file.status === "done") {
            await appStore.updateAvatar_(value['avatar'])
            setNewAvatar(value['avatar'])
        }
    };
    


    const modifiedAvatar = newAvatar || avatar

    return(
        <div style={{...hBox('center','center')}}>
            <div style={{...vBox('center','center')}}>
                <Avatar src={`${modifiedAvatar}?rand=${random(1000)}`} style={{ position : 'absolute', height:64,width:64}}/>
                {hasIcon ? <React.Fragment>
                    { editable && <ImgCrop rotate={true} width={80} height={80} contain={true}>
                    <Upload 
                        name={"file"}
                        action={host}
                        multiple={false}
                        showUploadList={false}
                        transformFile={transformFile}
                        data={getExtraData}
                        onChange={onChange()}
                    >
                        <IconButton aria-label='avatar-edit' style={{background : 'white',height : 24, width : 24, marginLeft : 40, marginTop : 36, border : '1px solid rgb(0,0,0,0.3)', boxShadow:'0px 4px 10px 0px #eeeeee'}}>
                            <Edit style={{height : 18, width : 18,color : 'rgb(0,0,0,0.4)'}}/>
                        </IconButton>
                    </Upload>
                    </ImgCrop>}
                </React.Fragment>                
                :
                <div style={{height : 24, width : 24, marginLeft : 36, marginTop : 36,}}/>
                }
            </div>
        </div>
    )
}

export default compose(inject('appStore'),observer)(AvatarEditButton)