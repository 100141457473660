import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'
import { useStaticQuery, graphql, Link ,navigate} from "gatsby"

import {hBox,vBox} from "gx-web-ui/utils/flex";

import { makeStyles } from '@material-ui/core/styles';
import SidePanelHeader from './SidePanelHeader'

//import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import CustomTextPairDisplay from '../common/CustomTextPairDisplay'
import EditIconButton from '../common/EditIconButton'
import {parseISO,format} from 'date-fns'

import {Tabs, List, Typography, Spin, Badge} from 'antd'
import { ExclamationCircleFilled, } from '@ant-design/icons';

const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    }
    
}))

const { TabPane } = Tabs;

const  MyDealsPanel = (props) => {

    const {appStore,label,closeFunction} = props

    const {loggedInUser} = appStore

    const classes = useStyle()
    const [deals, setDeals] = useState([])
    const [loading, setLoading] = useState(true)

    /*const deals = [
        {
            date: '11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Under reservation',
            agent: {
                name: 'Jason Chou',
                avatar: loggedInUser.avatarURL,
                agency: 'Home789',
                role: 'Agent',
            }
        },
        {
            date: '11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Under reservation',
            agent: {
                name: 'Jason Chou',
                avatar: loggedInUser.avatarURL,
                agency: 'Home789',
                role: 'Agent',
            }
        },
        {
            date: '11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Under reservation',
            agent: {
                name: 'Jason Chou',
                avatar: loggedInUser.avatarURL,
                agency: 'Home789',
                role: 'Agent',
            }
        },
        {
            date: '11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Under reservation',
            agent: {
                name: 'Jason Chou',
                avatar: loggedInUser.avatarURL,
                agency: 'Home789',
                role: 'Agent',
            }
        },
    ]*/

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            console.log(`loading data`)
            const res = await appStore.clientApi.getDeals_()
            //console.log('###deals: ', JSON.stringify(res[1], null, 4))

            if (res && Array.isArray(res)) {
                //console.log(res, null, 4)
                setLoading(false)
                setDeals(res.map(parseData))
            }
        } catch (err) {
            console.log(`error: ${err}`)
        }
    }

    const parseData = (e) => {
        const rawStatusMap = {
            'draft':'Reservation',
            'reserved':'Contract',
            'contractIssued':'Exchange',
            'exchanged':'Settlement',
            'settled':'Settled',
            'fellOver':'Fell over'
        }

        console.log(e)

        return {
            id: e.id,
            date: format(parseISO(e.updatedAt || e.createdAt), 'dd/MM/yyyy'),
            address: e[':listing'].name,
            type: e[":listing"]?.[':property']?.[':type']?.name,
            status: e.status === 'contractIssued' && e.submitStatus === 'approved' ? 'Settlement' : rawStatusMap[e.status],
            agent:{
                name: e[':agent'].preferredName||e[':agent']?.[':user'].name,
                avatar: e[':agent']?.[':user']?.avatar||null,
                agency: e[':agent']?.[':agency']?.name,
            },
            isFellOver: e.fellOver === 1
        }
    }

    const onClickItem = async (dealId) => {
        console.log('on click deal item')
        //todo
        //closeFunction()
        //navigate(`/deal?id=${dealId}`);

        window.open(`/deal/${dealId}`, '_blank')
    }

    if (loading) {
        return (
            <div style={{...vBox('center', 'center'), width: '100%', height: '100%'}}>
                <Spin />
            </div>
        )
    }

    return(
        <div style={{height : "100%"}}>
            <SidePanelHeader label={label} closeFunction = { closeFunction } backFunction={null} />
            <div style={{paddingLeft: 15, overflow : 'overlay', height : "calc(100% - 68px)"}}>
                <List
                    itemLayout="horizontal"
                    dataSource={deals}
                    renderItem={item => (
                        <List.Item onClick={() => onClickItem(item.id)}>
                            <div style={{padding: 10, paddingBottom: 25, backgroundColor: 'white', width: '100%'}}>
                                <Typography style={{color: 'grey', fontSize: 14}}>{item.isFellOver ? 'Failed' : 'Updated'} on {item.date}</Typography>
                                <Typography style={{fontSize: 16, fontWeight: '600', marginTop: 5, marginBottom: 5}}>{item.address}</Typography>
                                <div style={{...hBox('flex-start', 'flex-start'), padding: 0,}}>
                                    <Badge count={item.type} style={{backgroundColor: 'white', color: 'grey', borderWidth: 1, borderColor: 'grey'}} />
                                    {item.isFellOver ?
                                    <div style={{display: 'flex', marginLeft: 10, alignItems: 'center'}}>
                                        <Badge count={item.status} style={{backgroundColor: '#fdf4f4', color: '#df6054', marginLeft: 0, paddingLeft: 16}} />
                                        <ExclamationCircleFilled style={{color:'red',fontSize:10,position:'absolute',marginLeft:4,backgroundColor:"white"}}/>
                                    </div>
                                    :
                                    <Badge count={item.status} style={{backgroundColor: '#feefe6', color: '#f87e39', marginLeft: 10}} />
                                    }
                                </div>
                                <div style={{...hBox('center', 'flex-start'), width: '100%', padding: 10, border: '1px solid #e0e0e0', backgroundColor: 'white', borderRadius: 6, marginTop: 10}}>
                                    <Avatar src={item.agent.avatar} style={{width: 40, height: 40, marginRight: 10}} />
                                    <div>
                                        <div style={{...hBox('center', 'flex-start')}}>
                                            <Typography style={{color: 'black', fontSize: 15, marginRight: 10}}>{item.agent.name}</Typography>
                                            <Badge count={'Agent'} style={{backgroundColor: 'white', color: 'grey', borderWidth: 1, borderColor: 'grey'}} />

                                        </div>
                                        <Typography style={{color: 'grey', fontSize: 12}}>{item.agent.agency}</Typography>
                                    </div>
                                </div>
                            </div>
                        </List.Item >
                    )}
                />
            </div>
        </div>
    )
}

export default compose(inject('appStore','uiStore'),observer)(MyDealsPanel)
