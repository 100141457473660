import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Input from '@material-ui/core/Input';


const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : 'rgb(0,0,0,0.5)'
    },
    content : {
        width : "60%",
        color : 'black'
    }
}))


const  CustomTextPairDisplay= (props) => {
    


    const {label, content, isPassword=false, isEditing=false} = props
    const classes = useStyle()

    

    return(
        <div style={{...hBox('center'),marginTop : 12, marginBottom : 12,width : '100%'}}>
            <Typography className={classes.label} variant="subtitle1" style={{color : isEditing  && 'black'}} >
                {label}
            </Typography>
            {
                isPassword ? <Input className={classes.content} style={{color : 'black'}} disableUnderline={true} type='password' disabled value={content}/>
                :
                <Typography className={classes.content} variant="subtitle1" >
                    {content}
                </Typography>
            }
        </div>
    )
}

export default CustomTextPairDisplay