import React, { Component, useState, useEffect } from 'react'
// import { DebounceTouchableHighlight, ImageViewer,CustomVideo } from '@src/common'
// import PropertySummaryCover from './PropertySummaryCover'
import { Carousel } from 'react-responsive-carousel';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';

import PropertySummaryCover from './PropertySummaryCover'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import { motion } from "framer-motion"
import Ionicons from 'react-native-vector-icons/dist/Ionicons'

import "react-responsive-carousel/lib/styles/carousel.min.css";

const PropertyCard = (props) => {
    const {
        property,
        rootStyle,
        onClick,
        isDetailCover=true,
        showViewed=false,
        numberOfViewed=0,
        favored=false,
        onPressQRCode,
        onPressDirection,
        onToggleFavor,
        onAddInspection,
        height=220,
        motionProps,
        collectionDetails,
        swipeable=true,
    } = props
    const images = property.banners.filter(banner=>banner.urlM).map(banner => ({ url: banner.urlM }));

    if (images.length === 0 && property.banners.length > 0) {
        images.push({url: property.banners[0].placeholder})
    }

    const styles = useStyles()
    
    const [currentIndex, setCurrentIndex] = useState(0)
    return (
            <motion.div className={styles.root} style={{...(onClick && {cursor:'pointer'}),...rootStyle}} {...motionProps}>
                <PropertySummaryCover
                    property={property} 
                    onAddInspection={onAddInspection}
                    onToggleFavor={onToggleFavor}
                    onPressDirection={onPressDirection}
                    onPressQRCode={onPressQRCode}
                    favored={favored}
                    numberOfViewed={numberOfViewed} 
                    showViewed={showViewed}
                    isDetailCover={isDetailCover}
                    currentImageIndex={currentIndex}
                    collectionDetails={collectionDetails}
                >
                    <Carousel 
                        onClickItem={onClick}
                        swipeable={swipeable}
                        onChange={index=>setCurrentIndex(index)}
                        emulateTouch 
                        showIndicators={false} 
                        useKeyboardArrows={false} 
                        infiniteLoop
                        showStatus={false}
                        showThumbs={false}
                        dynamicHeight={false}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <ButtonBase onClick={onClickHandler} className={styles.buttonText} style={{left:8}} disableTouchRipple>
                                    <Ionicons name='chevron-down' size={20} color={'white'} style={{transform:'rotate(90deg)'}}/>
                                </ButtonBase>
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <ButtonBase onClick={onClickHandler} className={styles.buttonText} style={{right:8}} disableTouchRipple>
                                    <Ionicons name='chevron-down' size={20} color={'white'} style={{transform:'rotate(-90deg)'}}/>
                                </ButtonBase>
                            )
                        }
                    >
                    {images.map(({url},index)=>{
                        return <div key={index}>
                            <img src={url} className={styles.img} style={{height}}/>
                        </div>
                    })}
                    </Carousel>
                </PropertySummaryCover>
            </motion.div>
    )
}


const useStyles = makeStyles((theme) => createStyles({
    scrollView: {
        flex: 1,
    },
    root: {
        borderRadius:5,
        userSelect:'none',
        overflow:'hidden'
    },
    listView: {
    },
    image: {
        // flex: 1,
        width: '100%',
        height: '100%',
        resizeMode: 'cover'
    },
    video: {
        flex: 1,
        width: '100%',
        height: '100%',
        // borderRadius: 13,
    },
    wrapper: {
    },
    slideDefault: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paginationStyle: {
        bottom: 1
    },
    buttonWrapperStyle: {
        color: 'grey'
    },
    buttonText: {
        position: 'absolute',
        zIndex:1,
        fontWeight: 300,
        top:'calc(50% - 15px)',
        width:30,
        height:30,
        cursor:'pointer',
        fontSize: 34,
        color: 'white',
        filter: 'drop-shadow(0 2px 3px rgba(0, 0, 0, 0.3))'
    },
    img:{
        height:200,
        width:400,
        objectFit:'cover'
    }
    
}))

export default PropertyCard
