import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

export default withStyles(theme=>({
    root: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.primary.main
        },
    },
    checked: {}
}))(props=><Checkbox color="default" {...props}/>)