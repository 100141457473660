import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'
import ButtonBase from '@material-ui/core/ButtonBase';
import {hBox} from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root :{
        justifyContent : 'flex-start'
    }
}));

const CustomMenuItem = props=>{
    const {onClick, icon, label, isActivated, ...other} = props

    const classes = useStyles()

    return  <ButtonBase disableRipple onClick={onClick} className={classes.root} style={{'background' : 'transparent', height:64,width : "100%"}}>
        <div style={{...hBox('center','flex-start')}}>
            {
                isActivated ? 
                <div style={{...hBox('center','center'), background : '#ffffff', boxShadow:'0px 10px 4px -4px #eeeeee',  borderLeft: '2px solid black', height : 64, width : 64}}>
                    {icon}
                </div>
                :
                <div style={{...hBox('center','center'), height : 64, width : 64}}>
                    {icon}
                </div>
            }
            {
                label && <Typography style={{'color' : 'black',marginLeft:18}} variant="body1" >{label}</Typography>
            }
        </div>
                
    </ButtonBase>
}

export default CustomMenuItem