import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles';
export default withStyles(theme=>({
    root:{
        fontWeight:500,
        backgroundColor:'black',
        color:'white',
        paddingLeft:16,
        paddingRight:16,
        "&:hover":{
            backgroundColor:theme.palette.grey[600],
        },
        "&:disabled":{
            backgroundColor:theme.palette.grey[300],
            color:'white',
        }
    }
}))(Button)