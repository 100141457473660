import * as React from "react"

function SvgComponent(props) {
  return (
    <svg       
        width="1em"
        height="1em"
        viewBox="0 0 146 76" {...props}>
      <path
        d="M139.1 29.1l-12.7-5.7c-1.2-.6-2.3-1.5-3-2.6L114 6c-2.2-3.5-5.9-5.5-10.1-5.5H59.3c-3.5 0-6.9 1.6-9.1 4.3L35.4 22.5 7.8 33.2C3.3 35 .2 39.4.2 44.3v7.9c0 6.6 5.3 11.9 11.9 11.9h6.3c1.9 6.6 7.9 11.4 15.1 11.4 7.2 0 13.2-4.8 15.1-11.4h49c1.9 6.6 7.9 11.4 15.1 11.4 7.2 0 13.2-4.8 15.1-11.4h6.3c6.6 0 11.9-5.3 11.9-11.9V40c.1-4.8-2.7-9-6.9-10.9zM33.6 70.5c-5.9 0-10.7-4.8-10.7-10.7 0-5.9 4.8-10.7 10.7-10.7 5.9 0 10.7 4.8 10.7 10.7 0 5.9-4.8 10.7-10.7 10.7zm79.2 0c-5.9 0-10.7-4.8-10.7-10.7 0-5.9 4.8-10.7 10.7-10.7 5.9 0 10.7 4.8 10.7 10.7 0 5.9-4.8 10.7-10.7 10.7zm28.3-18.4c0 3.8-3.1 6.9-6.9 6.9h-5.7c-.4-8.3-7.3-15-15.7-15-8.4 0-15.3 6.6-15.7 15H49.2c-.4-8.3-7.3-15-15.7-15-8.4 0-15.3 6.6-15.7 15h-5.7c-3.8 0-6.9-3.1-6.9-6.9v-7.9c0-2.8 1.8-5.4 4.4-6.4l28.8-11.1L54 8c1.3-1.6 3.2-2.5 5.3-2.5H104c2.4 0 4.6 1.2 5.8 3.2l9.3 14.8c1.3 2 3.1 3.6 5.2 4.5l12.7 5.7c2.5 1.1 4.1 3.6 4.1 6.3v12.1z"
      />
    </svg>
  )
}

export default SvgComponent
