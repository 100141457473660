import { createMuiTheme} from '@material-ui/core/styles';
import OpenSansRegular from '@src/fonts/OpenSans/OpenSans-Regular.ttf'

const OpenSans = {
    fontFamily: 'OpenSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('OpenSans'),
      local('OpenSans-Regular'),
      url(${OpenSansRegular}) format('ttf')
    `,
    // unicodeRange:
    //   'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const typography = {
    button: {
        fontWeight: "500",
        fontSize: 13,
        // textTransform: "uppercase",
    },
    body1: {
        fontWeight: "400",
        fontSize: 14,
    },
    body2: {
        fontWeight: "400",
        fontSize: 13,
    },
    h4: {
        fontWeight: "400",
        fontSize: 22,
    },
    h5: {
        fontWeight: "400",
        fontSize: 20,
    },
    h6: {
        fontWeight: "400",
        fontSize: 18,
    },
    subtitle1: {
        fontWeight: "400",
        fontSize: 16,
    },
    subtitle2: {
        fontWeight: "500",
        fontSize: 15,
    },
    overline: {
        fontWeight: "400",
        fontSize: 12,
        textTransform: "uppercase",
    },
    caption: {
        fontWeight: "400",
        fontSize: 12,
    },
    footer: {
        fontWeight: "400",
        fontSize: 10,
    },
    caption2: {
        fontWeight: "400",
        fontSize: 11,
    }
}

const light = {
    // typography:{
    //     fontFamily:'OpenSans'
    // },
    palette:{
        primary: {
            main: '#000000' //your color
        },
        clientPrimary:{
            main:'#fa6400',
            light:'#FEE7DA'
        },
        agentPrimary:{
            main:'#009999',
            light:'#E7F3F6'
        },
        greyPrimary:{
            main: 'rgba(0, 0, 0, 0.3)'
        },
        error:{
            main:'#DD0000'
        },
        alert:{
            main:'#F5B601'
        }
    },
    overrides: {
        // MuiCssBaseline: {
        //     '@global': {
        //       '@font-face': [OpenSans],
        //     },
        // },
        MuiButton: {
            text: {
                textTransform:'none',
            },
            outlined:{
                textTransform:'none',
            },
            contained:{
                textTransform:'none',
            }
        },
    },
    breakpoints:{
        values:{
            tablet:1681
        }
    }
}
const theme = createMuiTheme(light);

export {theme,typography}
  