import React, { Component, useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import IconTint from 'react-icon-tint';
import Bed from '@src/components/icons/Bed'
import Bath from '@src/components/icons/Bath'
import Car from '@src/components/icons/Car'

const PropertySettingIcons = props=>{
    const {
        bedrooms,
        bathrooms,
        carspaces,
        textStyle,
        iconStyle,
        size=16,
        reverse=false,
        setStyle,
        color='#ffffff',
    } = props

    const spanStyle = {...styles.icon,...iconStyle,...hBox('center')}
    return  <div style={styles.footer}>
                {
                    (typeof bedrooms ==='string'||bedrooms >= 0) && <>
                            {reverse?
                            <div style={{...hBox('center'),...setStyle}}>
                                <span style={spanStyle}>
                                    <Bed height={size} width={size} fill={color} opacity={0.8}/>
                                </span>
                                <Typography variant='caption' style={{...styles.number,...textStyle,marginRight:8,color}}>{bedrooms}</Typography>
                            </div>
                            :
                            <div style={{...hBox('center'),...setStyle}}>
                                <Typography variant='body2'  style={{...styles.number,...textStyle,color}}>{bedrooms}</Typography>
                                <span style={{...spanStyle}}>
                                    <Bed height={size} width={size} fill={color} opacity={0.8}/>
                                </span>
                            </div>
                            }
                        </>
                }
                {
                    (typeof bathrooms ==='string'||bathrooms >= 0) && <>
                        {reverse?
                        <div style={{...hBox('center'),...setStyle}}>
                            <span style={spanStyle}>
                                <Bath height={size} width={size} fill={color} opacity={0.8}/>
                            </span>
                            <Typography variant='body2' style={{...styles.number,...textStyle,marginRight:8,color}}>{bathrooms}</Typography>
                        </div>
                        :
                        <div style={{...hBox('center'),...setStyle}}>
                            <Typography variant='body2' style={{...styles.number,...textStyle,color}}>{bathrooms}</Typography>
                            <span style={{...spanStyle}}>
                                <Bath height={size} width={size} fill={color} opacity={0.8}/>
                            </span>
                        </div>
                        }
                    </>
                }
                {
                    (typeof carspaces ==='string'||carspaces >= 0) && <>
                        {reverse?
                        <div style={{...hBox('center'),...setStyle}}>
                            <span style={{...spanStyle,marginLeft:-3}}>
                                <Car height={size+3} width={size+3} fill={color} opacity={0.8}/>
                            </span>
                            <Typography variant='body2' style={{...styles.number,...textStyle,color}}>{carspaces}</Typography>
                        </div>
                        :
                        <div style={{...hBox('center'),...setStyle}}>
                            <Typography variant='body2' style={{...styles.number,...textStyle,color}}>{carspaces}</Typography>
                            <span style={{...spanStyle}}>
                                <Car height={size+3} width={size+3} fill={color} opacity={0.8}/>
                            </span>
                        </div>
                        }
                        </>
                }
               
            </div>
}

const styles = {
    footer: {
        ...hBox('center'),
        // marginVertical: 2
    },
    number: {
        color:'white',
        fontWight:300,
        opacity:0.8,
        marginRight:2
    },
    icon: {
        marginRight: 4,
    },
}

export default PropertySettingIcons