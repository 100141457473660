import React,{ useState,forwardRef } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import ForgetForm from '../loginRelated/ForgetForm'
import { Divider, Modal } from "antd";


const ForgetModal = (props) => {
    const { visible, handleOk=false, handleClose,appStore,uiStore} = props


    return <Modal
                style={{padding : 0}}
                width={500}
                visible={visible}
                footer={null}
                // onOk={(e)=>{
                //     e.stopPropagation()
                //     console.log('ok')
                //     }
                // }
                // // okButtonProps={{disabled:!isConfirmable()}}
                onCancel={()=>{
                    handleClose()
                }}
                // okText={'Confirm'}
            >
                <ForgetForm closeFunction={()=>handleClose()} />            
            </Modal>
}
export default compose(inject('appStore','uiStore'),observer)(ForgetModal)
