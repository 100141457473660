
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

const CustomBackdrop = withStyles(theme=>({
    root:{
        backdropFilter:'blur(6px)',
        backgroundColor:"rgba(0,0,0,0.7)"
    }
}))(Backdrop)
export default CustomBackdrop