import React,{ useState,useRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'
import Countdown from "react-countdown";
import _, { set } from 'lodash'
import {hBox,vBox} from "gx-web-ui/utils/flex";
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../common/Loading';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ButtonBase from '@material-ui/core/ButtonBase'

import SidePanelHeader from '../setting/SidePanelHeader'
// import "antd/dist/antd.css";
import ConfirmIconButton from '../common/ConfirmIconButton'
import CustomTextPairDisplay from '../common/CustomTextPairDisplay'
import EditIconButton from '../common/EditIconButton'
import AvatarEditButton from '../common/AvatarEditButton'
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { ReactComponent as Edit } from '@src/assets/svgIcons/Edit.svg'
import { ReactComponent as Send } from '@src/assets/svgIcons/Send.svg'
import { ReactComponent as ArrowRight } from '@src/assets/svgIcons/ArrowRightWhite.svg'
// import ArrowRight from '@src/assets/svgIcons/ArrowRight.svg'

import {renderMobile,renderDate} from '../../utils/formatters'

import {DatePicker,message} from 'antd'
import {isValidMobilePhone} from '../../utils/typeCheck' 


const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    },
    radio : {
        color: '#c5c5c5',
        '&$checked': {
            color: "#fa6400",
        },
    },
    checked: {}

    
}))


const  VerifyEmailComponent= (props) => {


    const {appStore, email,isEmailVerified} = props

    const {loggedInPerson,getLoggedInPerson_,confirmUpdateUserProfile_} = appStore
    const [isEditing, setIsEditing] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [isUnverified, setIsUnverified] = useState(Boolean(isEmailVerified) ? false : true)
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [code , setCode] = useState('')
    const [countDownTime, setCountDownTime] = useState(Date.now() + 59000)
    const [userVerifyId, setUserVerifyId] = useState(null)

    useEffect(() => {

        setIsUnverified(Boolean(isEmailVerified) ? false : true)
        
     }, [isEmailVerified])


    const countDownRef = useRef()

    const onClickSend =(ref) =>{
        setCountDownTime(Date.now() + 59000)
        setIsCodeSent(true)
        appStore.client.post('send-code',{
            email : email
        }).then(({response})=>{

            console.log(response)
            setUserVerifyId(response.id)
            
        }).catch(error=>{
            message.error(error)
        })
    }

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            // setIsCodeSent(false)
            return <ButtonBase onClick={()=>onClickSend()}  style={{'background' : 'black',borderRadius : 4, height:37, width : 60}} >
                <span style={{'color' : 'white' }}>Resend</span>
            </ButtonBase>
        } else {
          // Render a countdown
          return <span>{seconds}s</span>;
        }
    };

    const handleVerify = () =>{
        appStore.client.get(['verify',userVerifyId],{
            email : email,
            code : code,
        }).then(({response})=>{
            setIsUnverified(false)
            // setIsChangeMobile(false)
            // setCountDownTime(Date.now() + 59000); 
            // setData({...set(data,'mobile', newMobile)})
            console.log(response)
        }).catch(error=>{
            message.error(error)
        })
    }

    const isValid = () =>{
        return code.length === 6
    }

    console.log(Boolean(isEmailVerified) ,isUnverified,'check here')
    return(
        <div>
            <div style={{...hBox('center','space-between')}}>
                <CustomTextPairDisplay style={{paddingRight : 24}} label={'Email'} content={email} />
                {
                    isUnverified && <React.Fragment>
                        {
                            isCodeSent ? <Countdown  key={countDownTime} date={countDownTime} renderer={renderer} ref={countDownRef}/> 
                            : 
                            <ButtonBase onClick={()=>onClickSend()}  style={{'background' : 'black',borderRadius : 4, height:30, width : 60}} >
                                <span style={{'color' : 'white' }}>Verify</span>
                            </ButtonBase>
                        }
                    </React.Fragment>
                }
            </div>
            {
                (isCodeSent && isUnverified) &&<div>
                    <Typography variant='subtitle1' style={{color : '#c5c5c5'}} >
                        Input the code
                    </Typography>
                    <div style={{...hBox('center','space-between')}}>
                        <TextField variant='outlined' onChange={e=>setCode(e.target.value)}  disabled={!isCodeSent}   style={{width : '100%' ,background :isCodeSent ? '#ffffff' : '#c5c5c5', paddingRight : 24}}/>
                        <ButtonBase disabled={!isValid()} onClick={handleVerify}style={{background : isValid() ? 'black' : 'rgb(0,0,0,0.3)',borderRadius : 4, height : 37}}>
                            <span style={{'color' : 'white'}}>Confirm</span>
                        </ButtonBase>
                    </div>
                </div>
            }
        </div>
    )
}

export default compose(inject('appStore','uiStore'),observer)(VerifyEmailComponent)