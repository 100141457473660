import React, { Component, useState, useEffect } from 'react'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import loadable from '@loadable/component'
import {ReactComponent as ThreeD} from '@src/assets/svgIcons/3D.svg'
import ButtonBase from '@material-ui/core/ButtonBase'
import FloorPlan from '@src/components/icons/FloorPlan'

const Viewer = loadable(() => import('react-viewer'))

const PropertyServiceAvailableCoverView = props=>{
    const [open, setOpen] = useState(false)

    const {VR = null,floorPlan=null} = props

    const floorPlanImages = [
        {
            src:floorPlan,
            alt:''
        }
    ]

    // const images = property.banners.map(banner => ({ url: banner.urlM }));
    return  <React.Fragment>
                {(VR || floorPlan) &&
                <div style={styles.actionView}>
                    {VR && 
                    <ButtonBase style={{marginBottom:4}} onClick={()=>window.open(VR,"blank")}>
                        <ThreeD height={20} width={20} fill={'white'}/>
                    </ButtonBase>
                    }
                    {floorPlan && 
                        <ButtonBase onClick={()=>setOpen(true)}>
                            <FloorPlan fill='white' width={20} height={20}/>
                        </ButtonBase>
                    }
                </div>
                }
                <Viewer visible={open} onClose={() => setOpen(false)} images={floorPlanImages} />
            </React.Fragment>
}

const styles = {
    actionView:{
        backgroundColor:'rgba(0, 0, 0, 0.38)',
        borderRadius:3,
        padding:4,
        marginBottom:4,
        ...vBox('center'),
        pointerEvents:'auto'
    }
}
export default PropertyServiceAvailableCoverView