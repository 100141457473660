import React,{useState,useRef} from 'react'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { hBox,vBox } from "gx-web-ui/utils/flex"
import compose from 'recompose/compose'
import {ReactComponent as ThreeD} from '@src/assets/svgIcons/3D.svg'
import {ReactComponent as Play} from '@src/assets/svgIcons/Play.svg'
import FloorPlan from '@src/components/icons/FloorPlan'
import findIndex from 'lodash/findIndex'
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import {typography} from '@src/theme/theme'
import {AUD} from '@src/utils/currency'
import PropertySettingIcons from './PropertySettingIcons'

const UnitCover = (props) => {
    const { 
        uiStore,
        children,
        data,
        currentIndex,
        setCurrentIndex,
        } = props
    const {thumbnail, name, address, price, propertyId, bedrooms,bathrooms,carspaces,banners, features, services, showTextForPrice, isProject } = data.processedData
    const styles = useCoverStyles()
    const hasVideo = Boolean(findIndex(banners,o=>Boolean(o.video))>0)
    const bottomRightActions = [
        {
            key:'floorPlan',
            Icon:FloorPlan,
            onClick:()=>{
                const floorPlanIndex = findIndex(banners,{url:services.floorPlan})
                if(floorPlanIndex){
                    setCurrentIndex(floorPlanIndex)
                }
            },
            disabled:!Boolean(services.floorPlan),
        },
        {
            key:'play',
            Icon:Play,
            onClick:()=>{
                const videoIndex = findIndex(banners,o=>Boolean(o.video))
                if(videoIndex){
                    setCurrentIndex(videoIndex)
                }  
            },
            disabled:!hasVideo,
        },
        {
            key:'3d',
            Icon:ThreeD,
            onClick:()=>window.open(services.VR,"blank"),
            disabled:!Boolean(services.VR),
        },
    ]
    return  <div className={styles.root}>
        {children}
        <div className={styles.gradient}/>
        <div className={styles.bottomRightActions}>
            {bottomRightActions.map(({Icon,onClick,disabled,key})=>(
                <ButtonBase key={key} disableRipple disabled={disabled} onClick={onClick}>
                    <Icon height={25} width={25} fill={disabled?'rgba(255,255,255,0.5)':'white'} className={styles.iconButton}/>
                </ButtonBase>
            ))}
        </div>
        <div className={styles.counter}>
            <Typography variant='subtitle1' className={styles.title}>{currentIndex+1}  /  {banners.length}</Typography>
        </div>
        <div className={styles.info}>
            <Typography variant='body1' className={styles.priceText}>{showTextForPrice?showTextForPrice: AUD(price).format()}</Typography>
            <Typography variant='body2' className={styles.addressTextLine1}>{address.fullText}</Typography>
            <PropertySettingIcons bedrooms={bedrooms} bathrooms={bathrooms} carspaces={carspaces} textStyle={{fontSize:11}}/>
        </div>
    </div>
}

const useCoverStyles = makeStyles(theme=>({
    root: {
        flex: 1,
        position: 'relative',
        overflow:'hidden'
    },
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(0deg, rgba(40,40,40,1) 0%, rgba(0,0,0,0) 50%)',
        pointerEvents:'none'
    },
    gradientTop:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(40,40,40,1) 0%, rgba(0,0,0,0) 20%, rgba(0,212,255,0) 100%)',
        pointerEvents:'none'
    },
    header:{
        position:'absolute',
        top:16,
        left:16,
        ...hBox('center'),
        pointerEvents:'none'
    },
    headerText:{
        // ...typography.body1,
        fontSize:22,
        fontWeight:300,
        color:'white'
    },
    image:{
        width:40,
        height:40,
        resizeMode: 'cover',
        marginRight:8,
    },
    topRightActions:{
        position:'absolute',
        top:16,
        right:8,
        ...hBox('center'),
    },
    iconButton:{
        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)',
        marginLeft:12,
        marginRight:8,
    },
    bottomRightActions:{
        position:'absolute',
        bottom:16,
        right:8,
        ...hBox('center'),
    },
    info:{
        position:'absolute',
        bottom:16,
        left:16,
    },
    counter:{
        position:'absolute',
        top:0,
        left:0,
        backgroundColor:theme.palette.grey[600],
        borderBottomRightRadius:5,
        paddingTop:2,
        paddingBottom:2,
        paddingRight:16,
        paddingLeft:16,
        ...hBox('center'),
    },
    title:{
        color:'white',
        fontSize:14
    },
    priceText: {
        fontWeight:500,
        color: 'white',
        fontSize:16,
    },
    addressTextLine1: {
        ...typography.body,
        color: 'white',
        fontSize:14,
        opacity:0.7
    },
}))

export default compose(
    inject('uiStore'),
    observer
)(UnitCover)