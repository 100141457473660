import { useCallback, useEffect, useState, useRef } from "react";
import { isLocalHost } from "./web";

export const useAsyncCall = (
    asyncFactory,
    deps,
    transformer
) => {
    const [state, setState] = useState({loading: true});
    const counter = useRef(0);
    const isMounted = useMountedState();
    const setProgress = (percentage) => setState({ ...state, loading: percentage || 1 });

    useEffect(() => {
        const callId = ++counter.current;

        asyncFactory(setProgress)
            .then(response => {
                if (!isMounted() || callId !== counter.current) {
                    return;
                }

                setState({
                    loading: false,
                    result: transformer ? transformer(response) : response,
                });
                return response;
            })
            .catch(error => {
                if (!isMounted() || callId !== counter.current) {
                    return;
                }

                setState({
                    ...state,
                    loading: false,
                    error: error.message ?? error.toString()
                });
                return error;
            });

        if (!state.loading) {
            setState({...state, loading: 1, error: undefined, cancelled: undefined});
        }
    }, deps || []);

    return {
        ...state,
        cancel: reason => {
            ++counter.current;
            setState({...state, loading: false, cancelled: reason || true});
        },
    };
};

export const useFirstMountState = () => {
    const isFirst = useRef(true);

    if (isFirst.current) {
        isFirst.current = false;

        return true;
    }

    return isFirst.current;
};

export const useUpdateEffect = (effect, deps) => {
    const isFirstMount = useFirstMountState();

    useEffect(() => {
        if (!isFirstMount) {
            return effect();
        }
    }, deps);
};

export const useMountedState = () => {
    const [getMountedState, setMountedState] = useStateInHook(false);

    useEffect(() => {
        setMountedState(true);
        return () => setMountedState(false);
    });

    return getMountedState;
};

export const useStateInHook = (initialValue) => {
    const mountedRef = useRef(initialValue);
    const getState = useCallback(() => mountedRef.current, []);
    const setState = (val) => (mountedRef.current = val);
    const applyState = (val) =>
        typeof mountedRef.current === "object" ? Object.assign(mountedRef.current, val) : (mountedRef.current = val);

    return [getState, setState, applyState];
};

export const useLifeCycleLogger = (componentName, ...rest) => {
    useEffect(() => {
        console.log(`${componentName} mounted`, ...rest);
        return () => console.log(`${componentName} unmounted`);
    }, []);

    useUpdateEffect(() => {
        console.log(`${componentName} updated`, ...rest);
    });
};

export const useAsyncMemo = (factory, deps, initial) => {
    const lastCallId = useRef(0);
    const isMounted = useMountedState();
    const [state, setState] = useState([ initial ]);

    useEffect(() => {
        const callId = ++lastCallId.current;       

        factory()
            .then((val) => {
                isMounted() && callId === lastCallId.current && setState([val]);
                return val;
            })
            .catch((err) => {
                isMounted() && callId === lastCallId.current && setState([ null, err ]);
                return err;
            });
    }, deps);

    return state;
};
