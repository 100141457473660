import React, { Component, useState, useEffect } from 'react'

import { Carousel } from 'react-responsive-carousel';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';

import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import ProjectSummaryCover from './ProjectSummaryCover'
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import { AUD } from '@src/utils/currency';
import sortBy from 'lodash/sortBy'
import PropertySettingIcons from './PropertySettingIcons'
import { motion } from "framer-motion"
import UnitModal from  '@src/components/property/UnitModal'
import Ionicons from 'react-native-vector-icons/dist/Ionicons'

import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProjectCard = props => {
    const {
        rootStyle,
        listMode=false,
        project,
        favored=false,
        onPressQRCode,
        onToggleFavor,
        onPressDirection,
        height=220,
        onClick,
        motionProps,
        isDetailCover=true,
        collectionDetails,
    } = props
    const images = project.banners.filter(banner=>banner.urlM).map(banner => ({ url: banner.urlM }));

    if (images.length === 0 && project.banners.length > 0) {
        images.push({url: project.banners[0].placeholder})
    }

    const styles = useStyles()
    const [selectedUnit, setSelectedUnit] = useState(null)
    return (
        <motion.div className={styles.root} style={{...rootStyle}} {...motionProps}>  
            {listMode &&
            <div className={styles.header}>
                <Avatar src={project.thumbnail} className={styles.image}/>
                <Typography className={styles.headerText}><strong style={{fontWeight:400}}>{project.name}</strong>· {project.address.city}</Typography>
            </div>
            }
            <div style={{...(onClick && {cursor:'pointer'})}}>
                <ProjectSummaryCover
                    favored={favored} 
                    project={project} 
                    detail={!listMode}
                    onPressQRCode={onPressQRCode} 
                    onToggleFavor={onToggleFavor} 
                    onPressDirection={onPressDirection}
                    isDetailCover={isDetailCover}
                    collectionDetails={collectionDetails}
                >
                    <Carousel 
                        onClickItem={onClick}
                        swipeable 
                        emulateTouch 
                        showIndicators={false} 
                        useKeyboardArrows={false} 
                        infiniteLoop
                        showStatus={false}
                        showThumbs={false}
                        dynamicHeight={false}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <ButtonBase onClick={onClickHandler} className={styles.buttonText} style={{left:8}} disableTouchRipple>
                                    <Ionicons name='chevron-down' size={20} color={'white'} style={{transform:'rotate(90deg)'}}/>
                                </ButtonBase>
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <ButtonBase onClick={onClickHandler} className={styles.buttonText} style={{right:8}} disableTouchRipple>
                                    <Ionicons name='chevron-down' size={20} color={'white'} style={{transform:'rotate(-90deg)'}}/>
                                </ButtonBase>
                            )
                        }
                    >
                    {images.map(({url},index)=>{
                        return <div key={index}>
                            <img src={url} className={styles.img} style={{height}}/>
                        </div>
                    })}
                    </Carousel>
                </ProjectSummaryCover>
            </div>
            {listMode &&
            <div className={styles.plan}>
                {sortBy(project.units.filter(item=>Boolean(item.rawData.isDisplayUnit)),o=>o.processedData.price).map((plan,index)=>{
                    const { processedData } = plan
                    return <div key={processedData.name}>
                                {index!==0 && <div className={styles.divider}/>}
                                <ButtonBase onClick={()=>setSelectedUnit(plan)} className={styles.btn}>
                                    <div className={styles.planLine}>
                                        <div>
                                            <Typography className={styles.planText}>
                                                {processedData.showTextForPrice?processedData.showTextForPrice: AUD(processedData.price).format()} · <span className={styles.unitText}>{processedData.name}</span>
                                            </Typography>
                                            <div className={styles.houseSetting}>
                                                <PropertySettingIcons {...processedData} color={'black'} size={18}/>
                                            </div>
                                        </div>
                                        <img src={require('@src/images/back-transparent.png')} className={styles.rightArrow} draggable={false}/>
                                    </div>
                                </ButtonBase>
                            </div>
                })}
            </div>
            }
            <UnitModal open={selectedUnit!==null} onClose={()=>setSelectedUnit(null)} unitData={selectedUnit} request project={project}/>
    </motion.div>
    )
}

const useStyles = makeStyles((theme) => createStyles({
    scrollView: {
        flex: 1,
    },
    header:{
        backgroundColor:"white",
        padding:8,
        ...hBox('center'),
    },
    headerText:{
        // ...typography.body1,
        fontSize:14,
        fontWeight:300,
        color:'black'
    },
    root: {
        borderRadius:5,
        overflow:'hidden',
        userSelect:'none',
    },
    listView: {
    },
    video: {
        flex: 1,
        width: '100%',
        height: '100%',
        // borderRadius: 13,
    },
    wrapper: {
    },
    slideDefault: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paginationStyle: {
        bottom: 1
    },
    buttonWrapperStyle: {
        color: 'grey'
    },
    buttonText: {
        position: 'absolute',
        zIndex:1,
        fontWeight: 300,
        top:'calc(50% - 15px)',
        width:30,
        height:30,
        cursor:'pointer',
        fontSize: 34,
        color: 'white',
        filter: 'drop-shadow(0 2px 3px rgba(0, 0, 0, 0.3))'
    },
    img:{
        height:220,
        width:400,
        objectFit:'cover'
    },
    image:{
        width:25,
        height:25,
        resizeMode: 'cover',
        marginRight:8,
    },
    planLine:{
        ...hBox('center','space-between'),
        padding:8
    },
    planText:{
        // ...typography.body1,
        fontWeight:500,
        color:'black'
    },
    unitText:{
        // ...typography.body1,
        fontWeight:400
    },
    plan:{
        backgroundColor:'white'
    },
    houseSetting:{
        paddingTop:4
    },
    divider:{
        width: '96%',
        height: 1,
        marginLeft:'2%',
        backgroundColor: theme.palette.grey[300],
    },
    rightArrow:{
        width:20,
        height:20,
        transform:'rotate(180deg)'
    },
    alignH:{
        ...hBox('center')
    },
    btn:{
        width:'100%',
        display:'block'
    }
}))

export default ProjectCard
