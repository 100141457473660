import * as React from "react"

function SvgComponent(props) {
  return (
    <svg       
        width="1em"
        height="1em" 
        viewBox="0 0 122 86" {...props}>
      <path
        d="M121 42.8v-.3c-1.2-12-11.8-16.4-18.9-17.4V9.8c0-.1-.5-9.7-11.2-9.7H30.6C19.9.1 19.4 9.7 19.4 9.9v15.2c-7.1 1-17.7 5.5-18.9 17.4v28.6h5.7v14h12v-14h85.3v14h12v-14h5.7V42.8h-.2zM30.5 5h60.3c5.5 0 6.1 4 6.2 4.9v15h-4v-6.4c0-2.3-1.6-5.8-6.1-5.8H70.1c-4.5 0-6.1 3.5-6.1 5.8v6.4H57.3v-6.4c0-2.3-1.6-5.8-6.1-5.8H34.4c-4.5 0-6.1 3.5-6.1 5.8v6.4h-4.1v-15C24.4 9 25 5 30.5 5zm38.6 19.9v-6.3c0-.9.6-.9 1.1-.9H87c.5 0 1 0 1.1.8v6.4h-19zm-35.7 0v-6.3c0-.9.6-.9 1.1-.9h16.8c.5 0 1 0 1.1.8v6.4h-19zm-11.6 5h77.7c1 0 15.1.8 16.5 13v10.4H5.3V42.9c1.4-12.1 15.6-13 16.5-13zM13 80h-2v-9h2v9zm97.3 0h-2v-9h2v9zM61.5 66.1H5.4v-7.8H116v7.8H61.5z"
      />
    </svg>
  )
}

export default SvgComponent
