import React, {useState} from 'react'
import { makeStyles,useTheme,withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import {typography} from '@src/theme/theme'
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import Switch from '@material-ui/core/Switch';
import BlackBtn from '@src/components/common/BlackBtn'
import Button from '@material-ui/core/Button'


const PropertyCardNote = (props) => {
    const {open,onClose,anchorRef,collectionDetails,collectionStore} = props
    const {
        updateNote_,
        updatePriceChangeNotification_,
        updateCollectionDetailsData
    } = collectionStore
    const styles = useStyles()
    const theme = useTheme()
    const [notification, setNotification] = useState(Boolean(collectionDetails.priceChangeNotify))
    const [note, setNote] = useState(collectionDetails.note||'')

    const onSave = async ()=>{
        await Promise.all([updateNote_(collectionDetails.listing,note),updatePriceChangeNotification_(collectionDetails.listing,notification)])
        updateCollectionDetailsData(collectionDetails.listing,notification,note)
        onClose()
    }
    
    return (
        <Popper className={styles.popper} id='notePopper' open={open} anchorEl={anchorRef && anchorRef.current} transition>
            {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
                <Paper>
                    <ClickAwayListener onClickAway={onClose}>
                        <div className={styles.root}>
                            <Typography className={styles.title}>Add note</Typography>
                            <TextField 
                                label="Notes" 
                                variant="outlined" 
                                margin="dense" 
                                fullWidth
                                multiline
                                rows={3}
                                value={note}
                                onChange={e=>setNote(e.target.value)}
                            />
                            <Typography className={styles.title}>Settings</Typography>
                            <div className={styles.switchLine}>
                                <Typography className={styles.label}>Price change notification</Typography>
                                <Switch color="primary" checked={notification} onChange={()=>setNotification(!notification)}/>
                            </div>
                            <div className={styles.alignH}>
                                <Button fullWidth variant='outlined' className={styles.cancelBtn} onClick={onClose}>
                                    Cancel
                                </Button>
                                <BlackBtn fullWidth onClick={onSave}>
                                    Save
                                </BlackBtn>
                            </div>
                        </div>
                    </ClickAwayListener>
                </Paper>
            </Fade>
            )}
        </Popper>
    )
}

const useStyles = makeStyles(theme=>({
    popper:{
        margin:8,
        zIndex:2
    },
    root:{
        backgroundColor:'white',
        borderRadius:5,
        minWidth:300,
        padding:16,
    },
    switchLine:{
        ...hBox('center','space-between')
    },
    title:{
       ...typography.subtitle2, 
    },
    label:{
        ...typography.body1, 
    },
    alignH:{
        ...hBox('center')
    },
    cancelBtn:{
        marginRight:8
    }
}))

export default compose(
    inject('propertyStore','collectionStore'),
    observer
)(PropertyCardNote)
