import React, { Component, useState, useRef } from 'react'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import { AUD } from '@src/utils/currency';
import { withResizeDetector } from 'react-resize-detector';
import compose from 'recompose/compose'
import {typography} from '@src/theme/theme'
import classnames from 'classnames'

// import { auAddressConstructor } from '@src/utils/address';
import PropertySettingIcons from './PropertySettingIcons'
import PropertyServiceAvailableCoverView from './PropertyServiceAvailableCoverView'
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import IconTint from 'react-icon-tint';
import { DirectionButton, QrCodeButton, FavorIconButton } from '@src/components/common'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import NoteIconButton from '@src/components/common/NoteIconButton'
import PropertyCardNote from '@src/components/property/PropertyCardNote'
import {inject,observer} from 'mobx-react'
import Box from '@material-ui/core/Box';


const PropertyStatusTag = props=>{
    const {type,style} = props
    const theme = useTheme()
    const styles = useStyles()
    const status = {
        "new":{
            title:"New",
            color:theme.palette.clientPrimary.main,
        },
        'underOffer':{
            title:"Under offer",
            color:theme.palette.grey[500]
        }
    }
    return <div className={styles.tag} style={{backgroundColor:status[type].color,...style}}>
        <Typography variant='caption' className={styles.tagText}>{status[type].title}</Typography>
    </div>
}

const animeVariants = {
    visible:{
        opacity:1
    },
    hidden:{
        opacity:0
    }
}

const PropertySummaryCover = props => {
    const {
        currentImageIndex,
        onAddInspection,
        onToggleFavor,
        onPressQRCode,
        onPressDirection,
        favored=false,
        property,
        children,
        isDetailCover=true,
        isAgent=false,
        width,
        collectionDetails,
        uiStore
    } = props
    const {checkLogin} = uiStore
    const styles = useStyles()
    const settingWidth = 80
    const [openNote, setOpenNote] = useState(false)
    const noteBtnRef = useRef(null)
    return <div className={styles.root}>
            {children}
            <div className={styles.gradient}/>
            <div className={classnames(styles.overlay,{[styles.shrinkOverlay]:!isDetailCover})}>
                <div className={styles.spaceBetween}>
                    <div>
                        {!isAgent && property.saleStatus &&  
                        <div className={styles.tagsContainer}>
                            <PropertyStatusTag type={property.saleStatus}/>
                        </div>
                        }
                        <div className={styles.alignH} style={{marginTop:isDetailCover?0:-4}}>
                            <Typography variant='body1' className={styles.priceText}>{property.showTextForPrice?property.showTextForPrice: AUD(property.price).format()}</Typography>
                            <Typography variant='body2' className={styles.apartmentText}>{property.propertyType}</Typography>
                        </div>
                        <div className={styles.alignH} style={{marginTop:isDetailCover?0:-4}}>
                            <span className={styles.alignH} style={{marginRight:4,maxWidth:200}} >
                                <IconTint src={require('@src/images/transparentLogo.png')} color='#ffffff' maxWidth={'13'} maxHeight={'13'}/>
                            </span>
                            {/* <Typography variant='body2' className={styles.addressTextLine1} style={{maxWidth:(width - settingWidth - 30)||300}}>{property.address.fullText}</Typography> */}
                            <Box component="div" className={styles.addressTextLine1} textOverflow="ellipsis">{property.address.fullText}</Box>
                        </div>
                    </div>
                    <div className={styles.corner}>
                        {isDetailCover ?  
                            <PropertyServiceAvailableCoverView VR={property.services.VR} floorPlan={property.services.floorPlan}/>
                            :
                            <PropertySettingIcons bedrooms={property.bedrooms} bathrooms={property.bathrooms} carspaces={property.carspaces} textStyle={{fontSize:11}}/>
                        }
                    </div>
                </div>
                {isDetailCover &&
                <div className={styles.spaceBetween}>
                    <Typography variant='caption' className={styles.agency}>{property.agencyName?`Listed by ${property.agencyName}`:''}</Typography>
                    <PropertySettingIcons bedrooms={property.bedrooms} bathrooms={property.bathrooms} carspaces={property.carspaces} textStyle={{fontSize:11}}/>
                </div>
                }
            </div>
            {(onToggleFavor || onPressDirection || onPressQRCode || collectionDetails ) &&
                <div className={classnames(styles.saveOverlay,{[styles.saveOverlaySimpleCard]:!isDetailCover})}>
                    <div className={styles.alignH}>
                        {onPressDirection && <DirectionButton onClick={onPressDirection} className={styles.iconButton} style={{ ...((onToggleFavor|| onPressQRCode) && {paddingRight:8})}}/>}
                        {onPressQRCode && <QrCodeButton onClick={onPressQRCode} className={styles.iconButton} style={{...(onToggleFavor && {paddingRight:8})}}/>}
                        {onToggleFavor && <FavorIconButton onToggleSave={onToggleFavor} className={styles.iconButton} isSaved={favored}/>}
                    </div>
                    {collectionDetails && 
                        <div style={{marginTop:8}}>
                            <NoteIconButton hasNote={collectionDetails.note!==null && collectionDetails.note!==''} onClick={checkLogin(()=>setOpenNote(!openNote))} className={styles.iconButton} ref={noteBtnRef}/>
                            <PropertyCardNote open={openNote} anchorRef={noteBtnRef} onClose={()=>setOpenNote(false)} collectionDetails={collectionDetails}/>
                        </div>
                    }
                </div>
            }
            {!isAgent && property.nextAvailableInspectionTime && onAddInspection &&
                <ButtonBase onClick={onAddInspection} className={styles.inspectionOverlay}>
                    <div style={{...hBox('center')}}>
                        <IconTint src={require('@src/images/planner.png')} color='#ffffff' maxWidth={'13'} maxHeight={'13'}/>
                        <Typography variant='caption' className={styles.inspectionText}>{property.nextAvailableInspectionTime}</Typography>
                    </div>
                </ButtonBase>
            }
    </div>
}
const useStyles = makeStyles((theme) => createStyles({
    root: {
        flex: 1,
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        padding: 8,
        width: '100%',
        bottom: 0,
        left: 0,
        ...vBox('baseline', 'space-between'),
        pointerEvents:'none',
    },
    shrinkOverlay:{
        padding: 4,
    },
    saveOverlay: {
        position: 'absolute',
        padding:12,
        top:0,
        right:0,
    },
    saveOverlaySimpleCard:{
        padding:4,
    },
    iconButton:{
        // shadowColor: "#000",
        filter: 'drop-shadow(0 2px 3px rgba(0, 0, 0, 1))'
        // shadowOffset: {
        //     width: 0,
        //     height: 2,
        // },
        // shadowOpacity: 1,
        // shadowRadius: 3.84,
    },
    inspectionOverlay:{
        position:'absolute',
        top:0,
        left:0,
        marginTop:0,
        paddingLeft:8,
        paddingRight:8,
        paddingTop:2,
        paddingBottom:2,
        backgroundColor:theme.palette.clientPrimary.main,
        borderBottomRightRadius:4,
    },
    eyeOverlay:{
        position:'absolute',
        top:0,
        left:0,
        marginTop:0,
        paddingVertical:4,
        ...hBox('center'),
    },
    spaceBetween:{
        width:'100%',
        ...hBox('bottom','space-between')
    },
    priceText: {
        fontWeight:600,
        color: 'white',
    },
    apartmentText: {
        ...typography.body2,
        color: 'white',
        paddingLeft: 8,
        // fontSize:12,
        // opacity:0.8
    },
    viewedText: {
        // ...typography.body2,
        color: 'white',
    },
    addressTextLine1: {
        ...typography.body,
        color: 'white',
        fontSize:12,
        opacity:0.8,
    },
    addressTextLocalLine: {
        // ...typography.body2,
        color: 'white',
        fontSize: 11,
    },
    alignH: {
        ...hBox('center')
    },
    viewed: {
        ...hBox('center'),
        color:'white',
        paddingLeft:8,
        paddingBottom:2
    },
    footer: {
        ...hBox('center'),
        marginVertical: 2
    },
    number: {
        // ...typography.body2,
        color: 'white'
    },
    icon: {
        marginLeft: 2,
        marginRight: 8,
        color: 'white'
    },
    eyeIcon: {
        marginLeft: 8,
        marginRight: 2,
        color: 'white'
    },
    star: {
    },
    linearGradient: {
        flex: 1,
        zIndex:3,
    },
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(0deg, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 40%)',
        pointerEvents:'none',
    },
    inspectionText: {
        // ...typography.caption,
        color: 'white',
        paddingLeft:8,
        fontWeight:300,
        fontSize:11,
    },
    corner:{
        ...vBox('flex-end')
    },
    tag:{
        paddingLeft:6,
        paddingRight:6,
        paddingTop:1,
        paddingBottom:1,
        borderRadius:3,
        ...vBox('center','center')
    },
    tagText:{
        ...typography.body2,
        color: 'white',
        // fontWeight:300
    },
    tagsContainer:{
        ...hBox('center'),
        paddingBottom:4
    },
    iconImage:{
        height:13,
        width:13,
        tintColor:'white'
    },
    logo:{
        width:13,
        height:13,
        marginRight:4,
    },
    agency:{
        fontWeight:300,
        fontSize:11,
        color:'white',
        opacity:0.8 
    }
}))

export default compose(inject('uiStore'),observer,withResizeDetector)(PropertySummaryCover)