
import React, { Component, useState, useEffect } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import  LVIcon  from './LVIcons'

const DirectionButton = props=>{
    const {onClick,...other} = props
    return  <ButtonBase disableRipple onClick={onClick}>
                <LVIcon name='direction' size={24} color='white' {...other}/>
            </ButtonBase>
}

export default DirectionButton