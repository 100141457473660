import * as React from "react"

function SvgComponent({ title, titleId, ...props }) {
  return (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 97 100"
        aria-labelledby={titleId}
        {...props}
        >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M43.5 99.1c-.8 0-1.5-.4-2-1h-38c-1.9 0-3.5-1.6-3.5-3.5v-91C0 1.7 1.6.1 3.5.1h37l15.7 15.7c1 1 1 2.6 0 3.5-1 1-2.6 1-3.5 0L38.4 5H4.9v88h36V56H30.5c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5h28c1.4 0 2.5 1.1 2.5 2.5S59.9 56 58.5 56H46v37h46V56H79.5c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5H92V5H66.5C65.1 5 64 3.9 64 2.5S65.1 0 66.5 0h27C95.4 0 97 1.6 97 3.5v91c0 1.9-1.6 3.5-3.5 3.5h-48c-.5.7-1.2 1.1-2 1.1z"
      />
    </svg>
  )
}

export default SvgComponent
