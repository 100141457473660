import React,{ useState,forwardRef } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox} from "gx-web-ui/utils/flex";

import _ from 'lodash'

import CustomizedInput from '../CustomizedInput'
import { message } from 'antd';

import Link from '@material-ui/core/Link';

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';

import Lock from '../../assets/icons/Lock.svg'
import VisibleButton from '../common/VisibleButton'
import User from '../../assets/icons/user.svg'


import { ReactComponent as Verified } from '@src/assets/svgIcons/Verified.svg'

import {renderMobile} from '../../utils/formatters'
import {isValidMobilePhone} from '../../utils/typeCheck'

import Countdown from "react-countdown";



const ForgetForm = (props) => {
    const { visible, handleOk=false, handleCancel,appStore,uiStore, closeFunction} = props

    const {login, register, setRegisteredData} = appStore

    const {setIsVerificationOpen,setOpenLogin} = uiStore

    const [mobileNo, setMobileNo] = useState({value : '', errorMessage : null})
    const [verificationCode, setVerificationCode] = useState({value : '', errorMessage : null})
    const [registerPassword, setRegisterPassword] = useState({value : '', errorMessage : null})
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState({value : '', errorMessage : null})
    const [isCodeSent, setIsCodeSent] = useState(false)
    const [isCodeSubmit, setIsCodeSubmit] = useState(false)
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)
    const [userRegId, setUserRegId] = useState(null)
    const [countDownTime, setCountDownTime] = useState(Date.now() + 59000)

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
            // setIsCodeSent(false)
            return <div style={{marginTop : 12, marginBottom : 12, ...hBox('center','flex-start')}}>
                <Typography variant='body1'>Didn't get a code?</Typography>
                <Link onClick={()=>resendCode()}  style={{'color' : 'orange','fontSize' : 16, 'paddingLeft' : 8}}/*to={`/sales-in-progress/pipeline/developerInformation?id=${dealId}`}*/>
                    Send again
                </Link>
            </div>
        } else {
          // Render a countdown
          return <div style={{marginTop : 12, marginBottom : 12, ...hBox('center','flex-end')}}>
              <span>{seconds}s</span>
          </div>
        }
    };


    const handleMobileNoInput = (value) =>{
        setMobileNo({'value' : value, errorMessage : null})
    }

    const handleCodeInput = (value) =>{
        setVerificationCode({'value' : value , errorMessage : null})
    }
    
    const handleRegisterPasswordInput = value =>{
        setRegisterPassword({'value' : value, errorMessage : null})
    }

    const handleRegisterConfirmPasswordInput = value =>{
        setRegisterConfirmPassword({'value' : value, errorMessage : null})
    }
    const handleVerify = () =>{
        setCountDownTime(Date.now() + 59000)
        if(isValidMobilePhone(mobileNo.value)){
            appStore.client.post('forget-password',{
                mobile : mobileNo.value,
            }).then(({response})=>{
                setUserRegId(response.id)
                setIsCodeSent(true)
            }).catch((error) => message.error(error))
        
            // call api
        }else{
            setMobileNo({value : '', errorMessage : 'Invalid mobile number!'})
        }
        
    }

    const resendCode = () =>{
        setCountDownTime(Date.now() + 59000)
        appStore.client.post('forget-password',{
            mobile : mobileNo.value,
        }).then(({response})=>{
            setUserRegId(response.id)
            
        }).catch((error) => message.error(error))
    }

    const handleSubmit = () =>{
        setIsCodeSubmit(true)
    }

    const handleResetPasswordSubmit = () =>{
        appStore.client.post(['change-password', userRegId],{
            code : verificationCode.value,
            password : registerConfirmPassword.value,
            reason : 'forgetPassword'
        }).then(({response})=>{
            message.success('Your password has been reset successfully!')
            closeFunction()
        }).catch(error=>{
            message.error(error?.error || error)
        })  
        
    }

    const isValidCode = () =>{
        return verificationCode.value.length === 6 ? true : false
    }

    const isValidPassword = ()=>{
        return (registerPassword.value === registerConfirmPassword.value && registerPassword.value !== "")? true : false
    }

    return <React.Fragment>
        
        {
            isCodeSubmit ? 
            <React.Fragment>
                <div style={{marginTop : 12, marginBottom : 12}}>
                    <Typography variant='h6'>Reset password</Typography>
                </div>
                <div style={{marginTop : 12, marginBottom : 12}}>
                    <CustomizedInput placeholderText='Password' value={registerPassword.value} errorMessage={registerPassword.errorMessage}  type={  isShowPassword ? 'text' : 'password'} onInputChange={(e)=>handleRegisterPasswordInput(e.target.value)}   addOnBefore = {<img src={Lock} style={{opacity: 0.3}}/>}   
                        addOnAfter={<VisibleButton showPassword={isShowPassword}   handleClickShowPassword={()=>setIsShowPassword(!isShowPassword)}/>}
                    />
                </div>
                {/* <div style={{...hBox('center','center'),marginTop:16}}> */}
                <div style={{marginTop : 12, marginBottom : 12}}>
                    <CustomizedInput placeholderText='Confirm password' value={registerConfirmPassword.value}  errorMessage={registerConfirmPassword.errorMessage}  type={  isShowConfirmPassword ? 'text' : 'password'} onInputChange={(e)=>handleRegisterConfirmPasswordInput(e.target.value)}   addOnBefore = {<img src={Lock} style={{opacity: 0.3}}/>}   
                        addOnAfter={<VisibleButton showPassword={isShowConfirmPassword}   handleClickShowPassword={()=>setIsShowConfirmPassword(!isShowConfirmPassword)}/>}
                    />
                </div>
                <div style={{marginTop : 12, marginBottom : 12}}>
                    <Button style={{'color':'white','background': isValidPassword() ? 'black' : 'rgb(0,0,0,0.5)','width':'100%','height' : 36,'textTransform' : 'none'}}  disabled={!isValidPassword()} onClick={handleResetPasswordSubmit}>Submit</Button>
                </div>
            </React.Fragment>
            :
            <React.Fragment>
                <div style={{marginTop : 12, marginBottom : 12}}>
                    <Typography variant='h6'>Let's find your account</Typography>
                </div>
                {
                    isCodeSent ? <React.Fragment>
                        <div style={{marginTop : 12, marginBottom : 12}}>
                            <Typography variant='body1'>{`Enter verification code Teamlink just sent to ${mobileNo.value}`} </Typography>
                        </div>
                        <div style={{marginTop : 12, marginBottom : 12}}>
                            <CustomizedInput placeholderText='Verification Code' value={verificationCode.value}  errorMessage={verificationCode.errorMessage}  onInputChange={(e)=>handleCodeInput(e.target.value)}  addOnBefore = {  <Verified style={{opacity: 0.3}}/>}/>
                        </div>
                        
                        <Countdown  key={countDownTime} date={countDownTime} renderer={renderer}/>
                        
                        <div style={{marginTop : 12, marginBottom : 12}}>
                            <Button style={{'color':'white','background': isValidCode() ? 'black' : 'rgb(0,0,0,0.5)','width':'100%','height' : 36,'textTransform' : 'none'}}  disabled={!isValidCode()} onClick={handleSubmit}>Submit</Button>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div style={{marginTop : 12, marginBottom : 12}}>
                            <CustomizedInput placeholderText='Mobile number' value={mobileNo.value}  errorMessage={mobileNo.errorMessage}  onInputChange={(e)=>handleMobileNoInput(e.target.value)}  addOnBefore = {<img src={User} style={{opacity: 0.3}} />}/>
                        </div>
                        <div style={{marginTop : 12, marginBottom : 12}}>
                            <Button style={{'color':'white','background':'black','width':'100%','height' : 36,'textTransform' : 'none'}} onClick={handleVerify}>Verify</Button>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        } 
    </React.Fragment>
}
export default compose(inject('appStore','uiStore'),observer)(ForgetForm)
