
import React, { Component, useState, useEffect } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import {hBox} from "gx-web-ui/utils/flex";

const ConfirmIconButton = props=>{
    const {onClick,style,...other} = props
    return  <ButtonBase disableRipple onClick={onClick} style={{...style,'background' : 'black',borderRadius : 4, height:42}}>
        <div style={{...hBox('center','center')}}>
            <CheckCircleOutlined name='save' size={24} style={{ color : 'white'}}/>
            <span style={{'color' : 'white',marginLeft:2}}>Confirm</span>
        </div>
    </ButtonBase>
}

export default ConfirmIconButton