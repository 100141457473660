const NSWStampDutyCalculator = (price,landUsage='home',propertyType='established',involvedFirstBuyer=false,foreignerInvolved=false,DuitableAmountForForeign=0)=>{
    let foreignAdditional = 0
    let totalDutySaving = 0 

    let stampDutyAmount = 0
    // price stage

    let deductor = 0
    let multiplier = 0
    let base = 0
    if(price <= 14000){
        deductor = 0
        multiplier = 1.25
        base = 0
    }else if(14001 <= price && price <= 31000){
        deductor = 14000
        multiplier = 1.5
        base = 175
    }else if(31001 <= price && price <= 83000){
        deductor = 31000
        multiplier = 1.75
        base = 430
    }else if(83001 <= price && price <= 310000){
        deductor = 83000
        multiplier = 3.50
        base = 1340
    }else if(310001 <= price && price <= 1033000){
        deductor = 310000
        multiplier = 4.50
        base = 9285
    }else if(1033001 <= price && price <= 3101000){
        deductor = 1013000
        multiplier = 5.50
        base = 41820
    }else if(3101001 <= price){
        deductor = 3101000
        multiplier = 7.00
        base = 155560
    }
    stampDutyAmount = Math.ceil((price - deductor) / 100) * multiplier + base
    //new buyer
    if(landUsage==='home'){
        if(!foreignerInvolved && involvedFirstBuyer && propertyType==='new'){
            if(price<=800000){
                totalDutySaving = stampDutyAmount
                stampDutyAmount = 0
            }else if(800000 < price && price <= 1000000){
                const newStampDuty = price * 0.201675 - 161340
                totalDutySaving = stampDutyAmount - newStampDuty
                stampDutyAmount = newStampDuty
            }
        }
        if(!foreignerInvolved && involvedFirstBuyer && propertyType==='established'){
            if(price<=650000){
                totalDutySaving = stampDutyAmount
                stampDutyAmount = 0
            }else if(650000 < price && price <= 800000){
                const newStampDuty = price * 0.2089 - 135785
                totalDutySaving = stampDutyAmount - newStampDuty
                stampDutyAmount = newStampDuty
            }
        }
        if(!foreignerInvolved && involvedFirstBuyer && propertyType==='vacant'){
            if(price<=400000){
                totalDutySaving = stampDutyAmount
                stampDutyAmount = 0
            }else if(400000 < price && price <= 500000){
                const newStampDuty = price * 0.17835 - 71340
                totalDutySaving = stampDutyAmount - newStampDuty
                stampDutyAmount = newStampDuty
            }
        }
    }
    // console.log(stampDutyAmount)

    if(foreignerInvolved){
        foreignAdditional = (DuitableAmountForForeign<=0? price: DuitableAmountForForeign) * 0.08
        stampDutyAmount = stampDutyAmount+foreignAdditional
    }
    return { stampDutyAmount, totalDutySaving, foreignAdditional }
}


export { NSWStampDutyCalculator };