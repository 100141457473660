import React,{ useState,forwardRef } from 'react'
// import compose from "recompose/compose";
import {hBox} from "gx-web-ui/utils/flex";

import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme) => ({
    root: {
    //   padding: '2px 4px',
        height : 42,
        padding : 4,
        width : "100%",
        display: 'flex',
        alignItems: 'center',
        background:'transparent',
        borderRadius : 4,
        border: '1px solid #ccc',
    },
    rootError: {
        //   padding: '2px 4px',
            height : 42,
            padding : 4,
            width : "100%",
            display: 'flex',
            alignItems: 'center',
            background:'transparent',
            borderRadius : 4,
            border: '1px solid red',
    },
    input: {
        paddingLeft:16,
        background:'transparent',
        flex: 1,
        '&::placeholder': {
            opacity: 0.3,
        }
    },
    iconButton: {
        padding: 10,
        borderRadius : 4,
        border : '1px solid #ccc',
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const CustomizedInput = (props) => {

    const {addOnBefore=null, placeholderText, addOnAfter=null,onInputChange,type, value,errorMessage=null, handlePressEnter=null} = props

    const classes = useStyles();

    return(
        <div style={{width : "100%"}}>
            <Paper component="form" elevation={0}  className={errorMessage ?  classes.rootError : classes.root} >
            {
                addOnBefore && 
                <React.Fragment>
                    <div style={{...hBox('center','center'), height : 36, width : 36}}>
                        {addOnBefore}
                    </div>
                    <Divider orientation="vertical" flexItem />
                </React.Fragment>                
            }
            <InputBase

                className={classes.input}
                placeholder={placeholderText}
                type={type}
                value={value}
                onKeyPress={e=>handlePressEnter(e)}
                // inputProps={classes.input}
                onChange={onInputChange}
            />
            {
                addOnAfter && <React.Fragment>
                    <Divider orientation="vertical" flexItem />
                    <div style={{...hBox('center','center'), height : 36, width : 36}}>
                        {addOnAfter}
                    </div>
                </React.Fragment> 
            }
            </Paper>
            {
                errorMessage && <FormHelperText style={{color : 'red'}}>{errorMessage}</FormHelperText>
            }
            
        </div>
        
    )
}

export default CustomizedInput
