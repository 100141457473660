import React,{useState} from 'react'
import Modal from '@material-ui/core/Modal';
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import { useAsyncCall } from 'gx-web-ui/utils/hook'
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import Fade from '@material-ui/core/Fade';
import {ReactComponent as Cross} from '@src/assets/svgIcons/Cross.svg'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import PropertyCarousel from '@src/components/property/PropertyCarousel'
import PropertyCalculator from '@src/components/property/PropertyCalculator'
import UnitCover from '@src/components/property/UnitCover'
import DetailPageSummary from '@src/components/property/DetailPageSummary'
import { useLocation } from '@reach/router'
import CircularProgress from '@material-ui/core/CircularProgress'
import CustomBackdrop from 'commons/components/CustomBackdrop';
import trim from 'lodash/trim'
import kebabCase from 'lodash/kebabCase'
import {propertySlugGeneratorFromProcessedData,propertySlugGeneratorFromRaw} from '@src/utils/slug'  


const UnitModal = (props) => {
    const {onClose,open,unitData,request=false,propertyStore, project, projectRawData} = props
    const styles = useStyles()
    const theme = useTheme()
    const [currentIndex, setCurrentIndex] = useState(0)
    const location = useLocation()
    const { loading, result:data, error } = useAsyncCall(async () => {
        if(request && unitData?.processedData?.propertyId){
           return await propertyStore.getPropertyDetails_(unitData.processedData.propertyId)
        }else{
            return unitData
        }
    }, [unitData?.processedData?.propertyId]);
    if(!loading){
        if(data === null || data === undefined){
            return null
        }
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            className={`${styles.modal} ignore-class`}
            BackdropComponent={CustomBackdrop}
        >
            <Fade in={open}>
                <div className={styles.root}>
                    <IconButton className={styles.cross} onClick={onClose}>
                        <Cross height={12} width={12} fill={theme.palette.grey[700]}/>
                    </IconButton>
                    <div className={styles.paper}>
                        {loading ?
                        <div className={styles.loadingContent}>
                            <CircularProgress/>
                        </div>
                        :
                        <React.Fragment>
                            <UnitCover 
                                data={data}
                                currentIndex={currentIndex}
                                setCurrentIndex={setCurrentIndex}
                            >
                                <PropertyCarousel 
                                    currentIndex={currentIndex} 
                                    setCurrentIndex={setCurrentIndex} 
                                    height={320} 
                                    banners={data.processedData.banners}
                                    onClickImage={()=>window.open(`${trim(location.origin,'/')}/${project?propertySlugGeneratorFromProcessedData(project):propertySlugGeneratorFromRaw(projectRawData)}/plan-gallery/${data.processedData.propertyId}`,'_blank')}
                                />
                            </UnitCover>
                            <div className={styles.content}>
                                <Typography className={styles.title}>Key details</Typography>
                                <DetailPageSummary data={data} listMode={false} unit/>
                                <div className={styles.divider}/>
                                <PropertyCalculator data={data} inModal/>
                            </div>
                        </React.Fragment>
                        }
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

const useStyles = makeStyles((theme) => ({
    root:{
        position:'relative',
        borderRadius:5,
        overflow:'hidden',
        maxHeight:'90vh',
        minHeight:300,
        width:700,
    },
    content:{
        padding:theme.spacing(4),
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(2),
        backgroundColor:theme.palette.grey[100],
        overflowY:'scroll',
        maxHeight:'calc(90vh - 320px)',
    },
    loadingContent:{
        height:400,
        ...vBox('center','center'),
    },  
    paper:{
        backgroundColor:'white',
        borderRadius:4,
        // minHeight:300,
        // maxHeight:650,
        // minWidth:300,
        // width:700,
        //padding:theme.spacing(2),
    },
    divider:{
        height:1,
        width:'100%',
        backgroundColor:theme.palette.grey[300],
        marginTop:16,
        marginBottom:16,
    },
    modal:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cross:{
        position:'absolute',
        top:4,
        right:4,
        zIndex:2,
    },
    icon:{
        marginTop:16,
        marginBottom:16,
    },
    buttonText: {
        position: 'absolute',
        zIndex:1,
        fontWeight: 300,
        top:'calc(50% - 20px)',
        width:40,
        height:40,
        cursor:'pointer',
        fontSize: 70,
        color: 'white',
        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)'
    },
    img:{
        objectFit:'cover',
        height:600
    },
    title:{
        fontWeight:600,
        fontSize:20,
        marginBottom:4
    },
}));

export default compose(
    inject('propertyStore'),
    observer
)(UnitModal)
