import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import React from 'react'

const StyledTabs = withStyles(theme=>({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height:4,
        '& > span': {
                maxWidth: 40,
                borderRadius:5,
                width: '100%',
                backgroundColor: theme.palette.clientPrimary.main,
        },
    },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.grey[500],
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
                opacity: 1,
        },
    },
    selected:{
        color: theme.palette.clientPrimary.main,
    }
}))((props) => <Tab disableRipple {...props} />);

export { StyledTabs ,StyledTab }