import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox,vBox} from "gx-web-ui/utils/flex";

import { makeStyles,fade,withStyles ,useTheme} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CustomTextPairDisplay from '../common/CustomTextPairDisplay'
import TextField from '@material-ui/core/TextField'

import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch'

import { ReactComponent as Edit } from '@src/assets/svgIcons/Edit.svg'
import IconButton from '@material-ui/core/IconButton';

import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider'
import ConfirmIconButton from '../common/ConfirmIconButton'
import {Tabs , message} from 'antd'
import SidePanelHeader from '../setting/SidePanelHeader'
import ForgetModal from './ForgetModal'

const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    },
    switchBase : {
        // color : theme.palette.clientPrimary.main,
        '&$checked' : {
            color : theme.palette.clientPrimary.main,
        },
        '&$checked + $track' : {
            backgroundColor : theme.palette.clientPrimary.main
        }
    },
    checked : {

    },
    track : {

    }
}))

const InputWithBorder = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: 0,
            width: '100%'
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        height : 36,
        padding: '0px 12px 0px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.1)} 0 0 0 0.1rem`,
            borderColor: theme.palette.greyPrimary.main,
        },
    },
}))(InputBase);

const { TabPane } = Tabs;

const  MySettingsPanel= (props) => {


    const {appStore,label,closeFunction} = props

    const {loggedInUser} = appStore
    const [isPassWordEditing, setIsPassWordEditing] = useState(false)
    const [isLanguageEditing, setIsLanguageEditing] = useState(false)
    const [isRegionEditing, setIsRegionEditing] = useState(false)
    const [isDisturbEditing, setIsDisturbEditing] = useState(false)
    const [isFindingAccount, setIsFindingAccount] = useState(false)

    const [isPassWordEditShow, setIsPassWordEditShow] = useState(false)
    const [isLanguageEditShow, setIsLanguageEditShow] = useState(false)
    const [isRegionEditShow, setIsRegionEditShow] = useState(false)
    const [isDisturbEditShow, setIsDisturbEditShow] = useState(false)

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")


    const [isDisturb, setIsDisturb] = useState(appStore.preference.disturb)

    const [city, setCity] = useState(appStore.preference.city)
    const [state, setState] = useState(appStore.preference.state)
    const [country, setCountry] = useState(appStore.preference.country)
    
    const theme = useTheme()
    const classes = useStyle(theme)



    const isValid = () =>{

        let result = {
            status : true,
            errorMes : ""
        }

        if(!oldPassword || !newPassword || !confirmPassword){
            result["status"] = false
            result["errorMes"] = "Password cannot be empty"
            return result
        }


        if(newPassword !== confirmPassword ){
            result["status"] = false
            result["errorMes"] = "Confirm password cannot be different with new password"
            return result
        }

        if(oldPassword === confirmPassword ){
            result["status"] = false
            result["errorMes"] = "New password cannot be same as old password"
            return result
        }

        return result
    }
    const onConfirm_ = async() =>{

        const res = isValid()

        if(!res.status){
            message.error(res.errorMes)
            return
        }

        const body = {
            "currentPassword": oldPassword,
            "newPassword": newPassword
        }

        try{
            await appStore.clientApi.changePassword_(body)
            message.success('Change password successfully')
            setIsPassWordEditing(false)
            setOldPassword("")
            setNewPassword("")
            setConfirmPassword("")
        }catch(error){
            message.error(error || "Change password failed")
        }

    }


    return(
        <div style={{height : "100%"}}>
            {
                isFindingAccount&&<ForgetModal visible={isFindingAccount} handleClose = {()=>setIsFindingAccount(false)} />
            }
            
            <SidePanelHeader label={label} closeFunction = { closeFunction } backFunction={null} />
            <div style = {{padding : 18,width : "100%", overflow : 'overlay', height : "calc(100% - 68px)"}}>
                <div onMouseEnter={()=>setIsPassWordEditShow(true)} onMouseLeave={()=>setIsPassWordEditShow(false)} style = {{...hBox('sketch')}}>
                    <CustomTextPairDisplay label={'Password'} content={'hahaha'} isPassword={true} isEditing={isPassWordEditing} />
                    {
                        isPassWordEditShow && <IconButton onClick={()=>setIsPassWordEditing(true)} style = {{position :'absolute', right : 18}}>
                            <Edit />
                        </IconButton>
                    }
                </div>
                {
                    isPassWordEditing && 
                    <div>
                        <div style={{marginBottom : 12}}>
                            <InputLabel shrink htmlFor="Old password" style={{marginBottom : 4}} >Old password</InputLabel>
                            <InputWithBorder type='password' onChange={(e)=>setOldPassword(e.target.value)} />
                        </div>
                        <div style={{marginBottom : 12}}>
                            <InputLabel shrink htmlFor="New password" style={{marginBottom : 4}}>New password</InputLabel>
                            <InputWithBorder type='password'  onChange={(e)=>setNewPassword(e.target.value)}/>
                        </div>
                        <div style={{marginBottom : 12}}>
                            <InputLabel shrink htmlFor="Old password" style={{marginBottom : 4}} >Confirm password</InputLabel>
                            <InputWithBorder  type='password' onChange={(e)=>setConfirmPassword(e.target.value)}/>
                        </div>
                        <div style={{marginBottom : 12}}>
                            <Typography variant='body2' style={{color : 'rgb(0,0,0,0.3)'}}>
                                Your password must between 8 to 30 characters, contains at least one uppercase, one lowercase and one number.
                            </Typography>
                        </div>
                        <div style={{marginBottom : 12}}>
                            <Link onClick={()=>setIsFindingAccount(true)} style={{'color' : 'black','textDecoration': 'underline','textDecorationColor': 'black'}} variant="body1"  /*to={`/sales-in-progress/pipeline/developerInformation?id=${dealId}`}*/>
                                Forgot password?
                            </Link>
                        </div>
                        <div style={{ ...hBox('center','space-between'),  marginBottom : 12}}>
                            <ButtonBase onClick={()=>setIsPassWordEditing(false)} style={{ width : "45%",'background' : 'transparent', 'border' : '1px solid #c5c5c5',borderRadius : 4, height:42}}>
                                <span style={{'color' : 'rgb(0,0,0,0.3)'}}>Cancel</span>
                            </ButtonBase>
                            <ConfirmIconButton style={{ width : "45%"}} onClick={onConfirm_}/>
                        </div>
                    </div>
                }
                <div><Divider /></div>
                <div onMouseEnter={()=>setIsLanguageEditShow(false)} onMouseLeave={()=>setIsLanguageEditShow(false)} style = {{...hBox('sketch')}}>
                    <CustomTextPairDisplay label={'Language'} content={'English'} isEditing={isLanguageEditing}/>
                    {
                        isLanguageEditShow && <IconButton onClick={()=>setIsLanguageEditing(true)} style = {{position :'absolute', right : 18}}>
                            <Edit />
                        </IconButton>
                    }
                </div>
                {
                    isLanguageEditing && <div style ={{...hBox('center','space-between')}}>
                        <ButtonBase onClick={()=>setIsLanguageEditing(false)} style={{ width : "45%",'background' : 'transparent', 'border' : '1px solid black',borderRadius : 4, height:42}}>
                            <span style={{'color' : 'black'}}>English</span>
                        </ButtonBase>
                        <ButtonBase onClick={()=>setIsLanguageEditing(false)} style={{ width : "45%",'background' : 'transparent', 'border' : '1px solid black',borderRadius : 4, height:42}}>
                            <span style={{'color' : 'black'}}>简体中文</span>
                        </ButtonBase>
                    </div>
                }
                
                <div><Divider /></div>
                <div onMouseEnter={()=>setIsRegionEditShow(true)} onMouseLeave={()=>setIsRegionEditShow(false)} style = {{...hBox('sketch')}}>
                    <CustomTextPairDisplay label={'Region'} content={`${appStore.preference.city}, ${appStore.preference.state}, ${appStore.preference.country}`} isEditing={isRegionEditing}/>
                    {
                        isRegionEditShow && <IconButton onClick={()=>setIsRegionEditing(true)} style = {{position :'absolute', right : 18}}>
                            <Edit />
                        </IconButton>
                    }
                </div>
                {
                    isRegionEditing && <div>
                        <div style={{marginBottom : 12}}>
                            <InputLabel shrink htmlFor="Country" style={{marginBottom : 4}}>Country</InputLabel>
                            <InputWithBorder onChange={(e)=>setCountry(e.target.value)}/>
                        </div>
                        <div style={{marginBottom : 12}}>
                            <InputLabel shrink htmlFor="State" style={{marginBottom : 4}}>State</InputLabel>
                            <InputWithBorder onChange={(e)=>setState(e.target.value)}/>
                        </div>
                        <div style={{marginBottom : 12}}>
                            <InputLabel shrink htmlFor="City" style={{marginBottom : 4}}>City</InputLabel>
                            <InputWithBorder onChange={(e)=>setCity(e.target.value)}/>
                        </div>
                        <div style={{ ...hBox('center','space-between'),  marginBottom : 12}}>
                            <ButtonBase onClick={()=>setIsRegionEditing(false)} style={{ width : "45%",'background' : 'transparent', 'border' : '1px solid #c5c5c5',borderRadius : 4, height:42}}>
                                <span style={{'color' : 'rgb(0,0,0,0.3)'}}>Cancel</span>
                            </ButtonBase>
                            <ConfirmIconButton onClick={()=>{setIsRegionEditing(false); appStore.setPreference({...appStore.preference, city : city, state : state, country : country})}} style={{ width : "45%"}}/>
                        </div>
                    </div>
                }
                
                {/* <div><Divider /></div>
                <div onMouseEnter={()=>setIsDisturbEditShow(true)} onMouseLeave={()=>setIsDisturbEditShow(false)} style = {{...hBox('sketch')}}>
                    {
                        !isDisturbEditing ? <CustomTextPairDisplay label={'Do not disturb'} content={appStore.preference.disturb ? 'On' : 'Off'} isEditing={isDisturbEditing} />
                        :
                        <div style={{...hBox('center'),marginTop : 12, marginBottom : 12, width : "100%"}}>
                            <Typography className={classes.label} style={{color : 'black'}} variant="subtitle1" >
                                {'Do not disturb'}
                            </Typography>
                            <Switch checked={isDisturb ? true : false}  onChange={()=>setIsDisturb(!isDisturb)} classes={{switchBase : classes.switchBase, checked : classes.checked, track : classes.track}}/>
                        </div>
                    }
                    {
                        isDisturbEditShow && <IconButton onClick={()=>setIsDisturbEditing(true)} style = {{position :'absolute', right : 18}}>
                            <Edit />
                        </IconButton>
                    }
                </div>
                {
                    isDisturbEditing && <div>
                        <div style={{ ...hBox('center','space-between'),  marginBottom : 12}}>
                            <ButtonBase onClick={()=>setIsDisturbEditing(false)} style={{ width : "45%",'background' : 'transparent', 'border' : '1px solid #c5c5c5',borderRadius : 4, height:42}}>
                                <span style={{'color' : 'rgb(0,0,0,0.3)'}}>Cancel</span>
                            </ButtonBase>
                            <ConfirmIconButton onClick={()=>{setIsDisturbEditing(false); appStore.setPreference({...appStore.preference, disturb : isDisturb})}} style={{ width : "45%"}}/>
                        </div>
                    </div>
                } */}

            </div>



        </div>
        
        
        
    )
}

export default compose(inject('appStore','uiStore'),observer)(MySettingsPanel)