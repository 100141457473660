import React,{ useState,useRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import {hBox,vBox} from "gx-web-ui/utils/flex";

import { makeStyles,fade,withStyles } from '@material-ui/core/styles';

//import Typography from '@material-ui/core/Typography';
//import Avatar from '@material-ui/core/Avatar';
import CustomTextPairDisplay from '../common/CustomTextPairDisplay'
import TextField from '@material-ui/core/TextField'

import InputLabel from '@material-ui/core/InputLabel'
import InputBase from '@material-ui/core/InputBase'
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch'

import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon'

import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider'
import ConfirmIconButton from '../common/ConfirmIconButton'
import {Tabs, List, Typography, Spin, Avatar, Calendar, Popover, Button, Input} from 'antd'
import SidePanelHeader from './SidePanelHeader'
import More from '@material-ui/icons/MoreHoriz'
import {parseISO,format} from 'date-fns'
import moment from 'moment';
import {AUD} from '@src/utils/currency'

import { ReactComponent as Add } from '@src/assets/svgIcons/Add_person.svg'
import { ReactComponent as Search } from '@src/assets/svgIcons/Search.svg'
import { ReactComponent as ArrowLeft } from '@src/assets/svgIcons/ArrowLeft.svg'
import { ReactComponent as ArrowRight } from '@src/assets/svgIcons/ArrowRight.svg'
import { ReactComponent as Pen } from '@src/assets/svgIcons/Pen.svg'

import PropertySettingIcons from '@src/components/property/PropertySettingIcons'
import {propertySlugGeneratorFromRaw} from '@src/utils/slug' 

const {Text} = Typography
const {TextArea} = Input

const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    }
    
}))

const InputWithBorder = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: 0,
            width: '100%'
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        height : 36,
        padding: '12px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.1)} 0 0 0 0.1rem`,
            borderColor: theme.palette.greyPrimary.main,
        },
    },
}))(InputBase);

const { TabPane } = Tabs

const  MyInspectionsPanel = (props) => {

    const {appStore,label,closeFunction, uiStore} = props

    const {setInspectionPropertyListingId,inspectionClicked, setInspectionClicked} = uiStore

    const {loggedInUser} = appStore
    const styles = useStyle()
    const [loading, setLoading] = useState(true)
    const [inspectionMap, setInspectionMap] = useState(null)
    const [inspections, setInspections] = useState([])

    const [currentDate, setCurrentDate] = useState('Today')

    useEffect(() => {
        loadData()
    }, [])

    const searchIcon = (
        <IconButton disableRipple onClick={() => {}} style = {{background : 'transparent', height : 40, width : 40}}>
            <Search />
        </IconButton>
    )

    const onSearch = (value) => {
        console.log(value)
    }

    const onAddContact = () => {
        console.log('on add contact')
    }

    const convertTime = (minutes) => {
        let hour = Math.floor(minutes / 60)
        let minute = minutes % 60
    
        const hourStr = hour < 10 ?  '0' + hour : hour > 12 ? '0' + (hour - 12) : hour  
        const minuteStr = minute < 10 ? '0' + minute : minute 
        const amOrPm = hour < 12 ? 'AM' : 'PM'
    
        return `${hourStr}:${minuteStr} ${amOrPm}`
    }
    
    const getISOTime = (date, time) => {
        //this method does not work on android platform
        //return DateTime.fromFormat(`${date}-${time}`,'yyyy-MM-dd-t')
        return `${date}T${time}`
    }


    const extractInspections = (data) => {
        console.log('############# extractInspections data :')
        console.log(data)
        
        const inspections = {}
    
        data.forEach(v => {
            //console.log(JSON.stringify(v, null, 2))
            const date = format(parseISO(v.date || v.startDate), 'yyyy-MM-dd')
            const startTime = convertTime(v.startTime)
            const endTime = convertTime(v.endTime)
            //console.log(date, startTime, endTime)
            let listing = v[':openInspection'] ? v[':openInspection'][':listing'] :
             (v[':privateInspection'] ? v[':privateInspection'][':listing'] : null)
            //console.log(JSON.stringify(resources, null, 4))
            const isPrivate = v.privateInspection !== null
    
            if (listing) {
                const resources = listing[':resourceGroup'][':resources']
    
                let property
                let bedrooms
                let bathrooms
                let carspaces
    
                const isProject = listing.mainCategory === 100
    
                if (isProject) { //project
                    property = listing[':project']
                }
                else {
                    property = listing[':property']
                    bedrooms = property[':attributes']?.BEDS?.value
                    bathrooms = property[':attributes']?.BATH?.value
                    carspaces = property[':attributes']?.PARK?.value
                }
                const banner = isProject ? property.logo : resources[0].url
                const address = {
                    line1: property[':address'].line1,
                    line2: property[':address'].line2,
                    state: property[':address'].state,
                    postcode: property[':address'].postcode,
                    city: property[':address'].city,
                    country: property[':address'].country,
                }
    
                // todo: check price existence
                //const price = listing[':prices']?.['LIST']?.['amount'] || 'Contact agent';
                const price = listing.showTextForPrice || listing[':prices']?.['LIST']?.['amount'] || 'Contact agent';
                const showTextForPrice = listing.showTextForPrice

                // raw Data to generate slug
                const rawData = {
                    id: property.id,
                    project: isProject ? undefined : true,
                    displayName: property.displayName,
                    [':address']: property[':address']
                };
    
                const propertyData = {
                    banner,
                    address,
                    price: AUD(price).format(), 
                    showTextForPrice,
                    bedrooms,
                    bathrooms,
                    carspaces,
                    id: property.id,
                }
    
                const inspectionItem = {
                    id: v.id,
                    date,
                    dateTime: getISOTime(date, startTime),
                    availableTimeRange:{
                        from:getISOTime(date, startTime),
                        to:getISOTime(date, endTime)
                    },
                    note: '',
                    propertyData,
                    isProject,
                    isPrivate
                }

                const ins = {
                    id: v.id,
                    startTime: startTime,
                    endTime: endTime,
                    image: banner,
                    address1: address.line1,
                    address2: address.line2,
                    address3: `${address.city}, ${address.state} ${address.postcode}`,
                    price: AUD(price).format(),
                    bedroom: bedrooms,
                    bathroom: bathrooms,
                    parking: carspaces,
                    isPrivate,
                    propertyListingId: listing.id,
                    note: v.note,
                    link: propertySlugGeneratorFromRaw(rawData),
                    projectName: isProject ? listing.name : ''
                }
    
                if (inspections[date] === undefined) {
                    inspections[date] = []
                }
    
                inspections[date].push(ins)
            }
        })
        //console.log(JSON.stringify(inspections, null, 4))
    
        return inspections
    }

    const loadData = async () => {
        const res = await appStore.clientApi.getAgendas()
        //console.log('###inspections: ', JSON.stringify(res, null, 4))

        if (res && Array.isArray(res)) {
            const ins = extractInspections(res)
            //console.log(JSON.stringify(ins, null, 4))
            setInspectionMap(ins)
            //console.log(JSON.stringify(inspectionMap, null, 4))
            setLoading(false)

            //console.log(ins)
            const now = moment()
            const inspectionsOfToday = ins[now.format('YYYY-MM-DD')]

            setCurrentDate(now.format('DD/MM/YYYY'))

            if (inspectionsOfToday) {
                setInspections(inspectionsOfToday)
            }
        }
    }

    const onClickItem = (item) => {
        setInspectionPropertyListingId(item.propertyListingId)
        setInspectionClicked(true)
    }

    const onDeleteItem = async (item) => {
        setInspections(inspections.filter(e => e.id !== item.id))
        const res = await appStore.clientApi.deleteAgenda_(item.id)
        console.log(res)
    }

    const onAddNote = async (item, note) => {
        item.note = note

        try {
            //todo
            const res = await appStore.clientApi.addNoteForAgenda_(item.id, note)
            console.log(res)
        } catch (err) {
            console.log(err)
        }
    }

    const renderItem = (item) => {
        return (
            <InspectionCard item={item} onClickItem={onClickItem} onDeleteItem={onDeleteItem} onAddNote={onAddNote} />
        )
    }

    const onPanelChange = (value, mode) => {
        console.log(value, mode);
    }

    const onSelect = (value) => {
        //console.log(value.format('YYYY-MM-DD'));
        //console.log(inspectionMap)
        const ins = inspectionMap[value.format('YYYY-MM-DD')]

        setCurrentDate(value.format('DD/MM/YYYY'))

        setInspections([])

        setTimeout(() => {
            if (ins) {
                setInspections(ins)
            } 
        }, 100)
    }


    /*const toPreviousMonth = () => {
        if (currentMonth == 0) {
            setCurrentYear(currentYear - 1)
        }

        setCurrentMonth(currentMonth == 0 ? 11 : currentMonth - 1)
    }

    const toNextMonth = () => {
        if (currentMonth == 11) {
            setCurrentYear(currentYear + 1)
        }


        setCurrentMonth(currentMonth == 11 ? 0 : currentMonth + 1)
    }*/


    if (loading) {
        return (
            <div style={{...vBox('center', 'center'), width: '100%', height: '100%'}}>
                <Spin />
            </div>
        )
    }

    return(
        <div>
            <div style={{paddingBottom: 50}}>
                <SidePanelHeader label={label} closeFunction = { closeFunction } backFunction={null} />
                <DateSelector {...{onSelect, inspectionMap}} />
                <Text style={{fontWeight: '500', fontSize: 18, marginLeft: 20}}>{`${currentDate} | ${inspections.length} inspection${inspections.length > 1 ? 's' : ''}`}</Text>
                <List
                    rowKey={e => e.id}
                    itemLayout="horizontal"
                    dataSource={inspections}
                    renderItem={renderItem}
            />
            <div style={{height: 50, backgroundColor: 'white'}} />
        </div>
    </div>
)
}

const DateSelector = (props) => {
    const {onSelect, inspectionMap,} = props
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

    const [showFullCalendar, setShowFullCalendar] = useState(false)

    const [currentMonth, setCurrentMonth] = useState(() => {
        const date = new Date()
        return date.getMonth()
    })

    const [currentYear, setCurrentYear] = useState(() => {
        const date = new Date()
        return date.getFullYear()
    })

    const switchFullCalendarState = () => {
        setShowFullCalendar(!showFullCalendar)
    }

    const getDateNumberOfMonth = month => {
        if (month === 3 || month === 5 || month === 7 || month === 8 || month === 10) {
            return 30
        } else if (month === 1) {
            if (currentYear % 4 === 0) {
                return 29
            } else {
                return 28
            }
        } else {
            return 31
        }
    }

    const today = React.useMemo(() => new Date(), []) 
    const day = today.getDay()
    const dateOfMonth = today.getDate()
    const currentDates = []
    //const firstDateOfMonth = 0

    const [selectedDate, setSelectedDate] = useState(today)

    const dateNumberOfMonth = getDateNumberOfMonth(currentMonth)

    for (let i = 0; i < 7; i++) {
        let temp = dateOfMonth + i - day
        let date = new Date()
        date.setDate(temp)


        currentDates.push(date)
    }

    const renderCalendarHeader = ({ value, type, onChange, onTypeChange }) => {
        //console.log(`header render, ${value}, ${type}, ${onChange}, ${onTypeChange}`)
        const onPreviousMonth = () => {
            if (currentMonth == 0) {
                setCurrentYear(currentYear - 1)
            }

            setCurrentMonth(currentMonth == 0 ? 11 : currentMonth - 1)

            const newValue = moment(value)
            newValue.month(currentMonth == 0 ? 11 : currentMonth - 1)
            onChange(newValue)
        }

        const onNextMonth = () => {
            if (currentMonth == 11) {
                setCurrentYear(currentYear + 1)
            }

            setCurrentMonth(currentMonth == 11 ? 0 : currentMonth + 1)

            const newValue = moment(value)
            newValue.month(currentMonth == 11 ? 0 : currentMonth + 1)
            onChange(newValue)
        }

        return showFullCalendar ? (
            <div style={{...hBox('center', 'space-between'), backgroundColor: 'white', flex: 1, width: '100%', height: '100%'}}>
                <IconButton disableRipple 
                onClick={onPreviousMonth} 
                style = {{background : 'transparent',position :'relative', left : 0}}>
                    <ArrowLeft />
                </IconButton>

                <div>
                    <Text style={{fontWeight: '500'}}>{months[currentMonth] + ' '}</Text>
                    <Text style={{fontWeight: '500'}}>{currentYear}</Text>
                </div>

                <IconButton disableRipple 
                onClick={onNextMonth} 
                style = {{background : 'transparent',position :'relative', left : 0}}>
                    <ArrowRight />
                </IconButton>
            </div>
        ) : null
    }

    const onSelectDate = (index) => {
        const date = currentDates[index]
        //date.setDate(currentDates[index])

        //console.log('on click', currentDates[index], selectedDate, date)
        //console.log(Object.keys(selectedDate))

        setSelectedDate(date)
        onSelect(moment(date))
    }

    const onSelectCalendarDate = (moment) => {
        onSelect(moment)

        const date = new Date()
        date.setDate(moment.date())
        date.setMonth(moment.month())
        date.setYear(moment.year())
        setSelectedDate(date)

        if (currentMonth !== moment.month()) {
            setCurrentMonth(moment.month())
        }
    }

    return (
        <div style={{...vBox('center', 'center')}}>
            {showFullCalendar ?
            <Calendar fullscreen={false} 
                onSelect={onSelectCalendarDate} 
                headerRender={renderCalendarHeader}
                dateFullCellRender={(moment) => {
                    const isSelected = moment.date() === selectedDate.getDate() && 
                    moment.month() === selectedDate.getMonth() && moment.year() === selectedDate.getFullYear()

                    const ins = inspectionMap[moment.format('YYYY-MM-DD')]
                    const indicator = ins ? <div style={{position: 'absolute', bottom: 6, width: 4, height: 4, backgroundColor:  isSelected ? 'white' : '#f76927', borderRadius: 2}}/> : null

                    return (
                        <div
                            style={{...hBox('center', 'center'), marginLeft: 15, backgroundColor: isSelected ? 'black' : 'white',
                            borderRadius: 16, width: 32, height: 32,
                            }}
                        >
                            <Text 
                            style={{color: isSelected ? 'white' : 'black',}}
                            >
                                {moment.date()}
                            </Text>
                            {indicator}
                        </div>
                    )
                }}
                dateCellRender={(date) => {
                    const ins = inspectionMap[date.format('YYYY-MM-DD')]

                    return ins ? <div style={{marginLeft: 10, width: 5, height: 5, backgroundColor: '#f76927', borderRadius: 15}}/> : null
                }}
                value={moment(selectedDate)}
            />
            :
            <div style={{...vBox('center', 'center'), width: '100%', marginBlock: 10}}>
                <div style={{...hBox('center', 'space-between'), paddingLeft: 20, paddingRight: 20, backgroundColor: 'white', flex: 1, width: '100%', height: '100%'}}>
                    {
                    days.map((e, i) => {
                        const isSelect = currentDates[i].getDate() === selectedDate.getDate()

                        const ins = inspectionMap[format(currentDates[i], 'yyyy-MM-dd')]
                        const indicator = ins ? <div style={{position: 'absolute', bottom: 2, width: 4, height: 4, backgroundColor:  isSelect ? 'white' : '#f76927', borderRadius: 2}}/> : null

                        return (
                        <div key={i} style={{...vBox('center', 'center')}} >
                            <Text 
                            style={{color: 'black'}}
                            >
                                {e}
                            </Text>
                            <div 
                            style={{...vBox('center', 'center'), backgroundColor: isSelect ? 'black' : 'white', width: 32, height: 32, borderRadius: 16, position: 'relative'}}
                            onClick={() => onSelectDate(i)} 
                            >
                                <Text 
                                style={{color: isSelect ?'white':'black', cursor: 'pointer'}}
                                >
                                    {currentDates[i].getDate()}
                                </Text>
                                {indicator}
                            </div>
                        </div>
                    )
                    })
                    }
                </div>
            </div>
            }
            <div style={{...hBox('center', 'center'), width: '100%', backgroundColor: 'white', borderRadius: 0, boxShadow:`0px 2px 2px 0px #a0a0a060`, marginBottom: 18, marginTop: 0}}
            >
                <div style={{...hBox('center', 'center'), width: 80, backgroundColor: 'white', borderRadius: 12, boxShadow:`0px 2px 2px 0px #a0a0a060`, position: 'relative', top: 8}}
                onClick={switchFullCalendarState}
                >
                    <ArrowLeft style={{width: 60, transform: [`rotate(${showFullCalendar ? '90' : '270'}deg)`]}} />
                </div>
            </div>

        </div>
    )
}

const InspectionCard = (props) => {
    const {item, onClickItem, onDeleteItem, onAddNote} = props
    const [isHover, setIsHover] = useState(false)
    const baseStyle = {backgroundColor: 'white', flex: 1, border: '1px solid #e0e0e0', borderLeft: item.isPrivate ? '3px solid #898988' : '3px solid #f95f52', padding: 10, paddingTop: 0}
    //const cardStyle = isHover ? Object.assign(baseStyle, {boxShadow:`4px 4px 8px 0px #a0a0a0a8`}) : baseStyle 
    const cardStyle = baseStyle 
    const [isShowPopup, setIsShowPopup] = useState(false);
    //const [note, setNote] = useState(item.note || '');
    const [isEditing, setIsEditing] = useState(false)
    const textAreaRef = useRef(null)
    const [note, setNote] = useState(item.note || '')
    const [ textAreaValue, setTextAreaValue ] = useState('')

    useEffect(() => {
        //setNote(item.note)
    }, [item])

    //console.log('note content', item.note)

    const onClickAdd = () => {
        setIsShowPopup(false)
        setIsEditing(true)
    }

    const onClickDelete = () => {
        setIsShowPopup(false)
        onDeleteItem(item)
    }

    const onClickMore = (e) => {
        console.log('click more', e)
        e.stopPropagation()
        setIsShowPopup(!isShowPopup)
    }

    const onBlur = (e) => {
        //console.log('v>>>', note)
        //console.log('on blur', textAreaRef.current.state.value)
        //setNote(textAreaRef.current.state.value)
        setIsEditing(false)
        onAddNote(item, note)
    }

    const onChange = (e) => {
        //const keyword = inputRef.current.state.value
        //console.log(e.target.value)
        setNote(e.target.value)
    }

    const onSave = ()=>{
        console.log( textAreaValue )
        setIsEditing(false)
        // onAddNote(item, textAreaRef?.current?.state?.value)
        onAddNote(item, textAreaValue)
    }

    const onCancel = ()=>{
        setIsEditing(false)
    }

    const onPropertyClick = () => window.open(`/${item.link}`,'_blank');

    const content = (
        <div style={{...vBox('flex-start', 'flex-start'), flex: 1}}>
        <Button type='link' onClick={onClickAdd} style={{...hBox('center', 'flex-start')}}>
            <Text style={{fontSize: 15}}>Add note</Text>
        </Button>
        <Button type='link' onClick={onClickDelete} style={{...hBox('center', 'flex-start')}}>
            <Text style={{fontSize: 15, }}>Delete</Text>
        </Button>
        </div>
    )

    return (
        <List.Item onClick={() => onClickItem(item)} style={{backgroundColor: 'white', padding: 0} }>
            <div style={{...hBox('flex-start', 'flex-start'), padding: 20, paddingTop: 10, paddingBottom: 10, backgroundColor: 'white', width: '100%'}}
             onMouseEnter={() => setIsHover(true)} 
             onMouseLeave={() => setIsHover(false)}
             >
                <div style={{marginRight: 10}}>
                    <Typography style={{color: 'black', fontSize: 14}}>{item.startTime}</Typography>
                    <Typography style={{color: 'grey', fontSize: 14}}>{item.endTime}</Typography>
                </div>
                <div style={{backgroundColor: 'white', flex: 1}}>
                    <div style={cardStyle}>
                        <div style={{...hBox('center', 'space-between'), backgroundColor: 'white', paddingLeft: 0, paddingBottom: 5}}>
                            <Typography style={{color: 'black', fontSize: 15, fontWeight: '500'}}>{item.isPrivate ? 'Private inspection' : 'Open inspection'}</Typography>
                            <Popover placement={'bottomRight'} content={content} trigger="click" visible={isShowPopup} onVisibleChange={(visible) => setIsShowPopup(visible)}>
                                <Button type='link' onClick={onClickMore}>
                                    <More style={{color: 'grey', marginTop: 5}} /> 
                                </Button>
                            </Popover>
                        </div>
                        <a onClick={onPropertyClick}>
                            <div style={{...hBox('flex-start', 'flex-start')}}>
                                <Avatar shape={'square'} size={80} src={item.image} />
                                <div style={{backgroundColor: 'white', marginLeft: 10}}>
                                    { item.projectName && <Typography style={{color: 'black', fontSize: 14, fontWeight: 'bold' }}>{item.projectName}</Typography> }
                                    <Typography style={{color: 'black', fontSize: 14}}>{item.address1}</Typography>
                                    { item.address2 && <Typography style={{color: 'grey', fontSize: 14}}>{item.address2}</Typography> }
                                    { item.address3 && <Typography style={{color: 'grey', fontSize: 14}}>{item.address3}</Typography> }
                                    <Typography style={{color: 'rgb(249, 95, 82)', fontSize: 14, fontWeight: '500'}}>{item.price === "$0" ? "Sale by Negotiation" : item.price}</Typography>
                                    <PropertySettingIcons bedrooms={item.bedroom} bathrooms={item.bathroom} carspaces={item.parking} color={'black'} size={18}/>
                                </div>
                            </div>
                        </a>
                    </div>
                    {/* {(isEditing || item.note) &&
                    <div style={{...hBox('flex-start', 'flex-start'), padding: 10, border: '1px solid #e0e0e0', borderTopWidth: 0}}>
                        <Icon component={Pen} style={{color: 'red', marginTop: 4, marginRight: 10, width: 16, height: 16}} /> 
                        <TextArea  defaultValue={item.note} ref={textAreaRef} onChange={onChange} onBlur={onBlur} autoSize={{minRows: 1, maxRows: 3}} style={{backgroundColor: 'white', padding: 2, borderWidth: 0, fontSize: 15, resize: 'none'}} placeholder={''} bordered={false} />
                    </div>
                    } */}
                    {(isEditing ) &&
                    <div style={{...hBox('flex-start', 'flex-start'), padding: 10, border: '1px solid #e0e0e0', borderTopWidth: 0,flexDirection:"column"}}>
                        <div style={{display:'flex',flexDirection:"row"}}>
                            <Icon component={Pen} style={{color: 'red', marginTop: 4, marginRight: 10, width: 16, height: 16}} /> 
                            {/* <TextArea  defaultValue={item.note} ref={textAreaRef} onBlur={onBlur} autoSize={{minRows: 1, maxRows: 3}} style={{backgroundColor: 'white', padding: 0, borderWidth: 0, fontSize: 15, resize: 'none'}} placeholder={''} /> */}
                            <TextArea onChange={(e)=>{setTextAreaValue(e.target.value)}}  defaultValue={item.note} ref={textAreaRef}  autoSize={{minRows: 1, maxRows: 3}} style={{backgroundColor: 'white', padding: 0, borderWidth: 0, fontSize: 15, resize: 'none'}} placeholder={''} />
                        </div>
                        <div style={{display:'flex',flexDirection:"row"}}>
                                <span onClick={onSave} style={{fontSize:"14px",marginLeft:'8px',marginTop:'5px',cursor:'pointer'}}>Save</span>
                                <span onClick={onCancel} style={{fontSize:"14px",marginLeft:'8px',marginTop:'5px',cursor:'pointer'}}>Cancel</span>
                        </div>
                    </div>    
                    }
                </div>
            </div>
        </List.Item >
    )
}

export default compose(inject('appStore','uiStore'),observer)(MyInspectionsPanel)
