import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'
import { useStaticQuery, graphql, Link ,navigate} from "gatsby"

import {hBox,vBox} from "gx-web-ui/utils/flex";

import { makeStyles } from '@material-ui/core/styles';
import SidePanelHeader from './SidePanelHeader'

//import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import CustomTextPairDisplay from '../common/CustomTextPairDisplay'
import EditIconButton from '../common/EditIconButton'

import {Tabs, List, Typography, Spin, Badge, Button} from 'antd'
import pdf from '@src/assets/icons/pdf.png'
import {parseISO,format} from 'date-fns'
import { editLeasingApplicationSlug } from '../../utils/slug';

const useStyle  = makeStyles((theme) => ({
    label : {
        width : "30%",
        color : '#c5c5c5'
    },
    content : {
        width : "60%"
    }
    
}))

const { TabPane } = Tabs;

function MyLeasingPanel(props) {

    const {appStore,label,closeFunction} = props

    const {loggedInUser} = appStore

    const classes = useStyle()
    const [leasings, setLeasings] = useState([])
    const [loading, setLoading] = useState(true)
    /*const leasings = [
        {
            date: '15:23 11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Approved',
        },
        {
            date: '08:30 11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Submitted',
        },
        {
            date: '16:53 11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Rejected',
        },
        {
            date: '11/08/2020',
            address: '1/10 Barden St, Arncliff, NSW 2205',
            type: 'Apartment',
            status: 'Approved',
        },
    ]*/

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const res = await appStore.clientApi.getLeasings_()
        console.log('###leasing applications: ', JSON.stringify(res, null, 4))

        if (res && Array.isArray(res)) {
            //console.log(res, null, 4)
            setLoading(false)
            //todo
            setLeasings(res.map(parseData))
        }
    }

    const parseData = (e) => {
        return {
			date: format(parseISO(e.updatedAt || e.createdAt), "dd/MM/yyyy"),
			address: e[":listing"].name,
			type: e[":listing"]?.[":property"]?.[":type"]?.name,
			status: e.status.substr(0, 1).toUpperCase() + e.status.substr(1),
			applicationForm: e.applicationForm,
            rawData:e
		}
    }

    const onClickItem = () => {
        console.log('on click deal item')
    }

    if (loading) {
        return (
            <div style={{...vBox('center', 'center'), width: '100%', height: '100%'}}>
                <Spin />
            </div>
        )
    }

    return(
        <div style={{height : "100%"}}>
            <SidePanelHeader label={label} closeFunction = { closeFunction } backFunction={null} />
            <div style={{paddingLeft: 15, height : "calc(100% - 68px)", overflow : "overlay"}}>
                <List
                    itemLayout="horizontal"
                    dataSource={leasings}
                    renderItem={item => <LeasingItem leasing={item} onClick={() => {}} />}
                />
            </div>
        </div>
    )
}

function LeasingItem(props) {
    const {onClick, leasing} = props
    const {
        rawData,
        type,
        status,
        date,
        address,
        applicationForm
    } = leasing
    const color = status === 'Approved' ? '#80d25c' : status === 'Submitted' ? '#f2b949' : '#939393'
    const bgColor = status === 'Approved' ? '#eef9e9' : status === 'Submitted' ? '#fdf7eb' : '#e6e6e6'

    return (
        <List.Item onClick={onClick}>
            <div style={{padding: 10, backgroundColor: 'white', width: '100%'}}>
                <div style={{...hBox('flex-start', 'flex-start'), padding: 0,}}>
                    <Badge count={type} style={{backgroundColor: 'white', color: 'grey', borderWidth: 1, borderColor: '#e0e0e0', borderRadius: 4}} />
                    <Badge count={status} style={{backgroundColor: bgColor, color: color, marginLeft: 10, borderRadius: 4}} />
                    <div style={{flex: 1}} />
                    <Typography style={{color: 'grey', fontSize: 13}}>{date}</Typography>
                </div>
                <div style={{...hBox('center', 'flex-start'), paddingTop: 10}}>
                    <Typography style={{fontSize: 14, fontWeight: '600', marginTop: 5, marginBottom: 0}}>{address}</Typography>
                    <div style={{flex: 1}} />
                    {applicationForm && 
                    <img src={pdf} style={{width: 25, height: 25,cursor:'pointer'}} onClick={()=>window.open(applicationForm,'_blank')}/>
                    }
                    {rawData.id && rawData.status === 'draft' && 
                        <Button onClick={() => window.open(editLeasingApplicationSlug(rawData.id, address, rawData[":agent"][":agency"]["id"]), "_blank")}>Edit</Button>
                    }
                </div>
            </div>
        </List.Item >
    )
}

export default compose(inject('appStore','uiStore'),observer)(MyLeasingPanel)