import React, { useEffect, useState, forwardRef } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import LVIcon from './LVIcons'
// import Favor from '@src/components/icons/Favor'
import {ReactComponent as Favor} from '@src/assets/svgIcons/Star.svg'
import {ReactComponent as Saved} from '@src/assets/svgIcons/saved.svg'

const FavorIconButton = (props,ref) => {
    // const [isSaved, setSave] = useState(props.isSaved || false)
    const { onToggleSave = () => { }, inactiveColor='white',className, size=20 } = props
    const _onToggle = () => {
        onToggleSave(!props.isSaved)
        // setSave(!isSaved)
    }
    // useEffect(() => {
    //     setSave(props.isSaved)
    // }, [props.isSaved])

    return <ButtonBase disableRipple onClick={() => _onToggle()} ref={ref}>
        {props.isSaved ?
            <Saved height={size} width={size} className={className}/>
            :
            <Favor height={size} width={size} fill={inactiveColor} className={className}/>
        }
    </ButtonBase>
}


export default forwardRef(FavorIconButton)
