import React from 'react'
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { AUD } from '@src/utils/currency'
import Button from '@material-ui/core/Button';
import { typography } from '@src/theme/theme'
import PropertySettingIcons from './PropertySettingIcons'
import cx from 'classnames'
import { navigate } from "gatsby"
import { hBox, vBox } from "gx-web-ui/utils/flex";
import { inject, observer } from 'mobx-react'
import compose from "recompose/compose";
import { common } from '@material-ui/core/colors';
import { message } from 'antd';
import { leasingApplicationSlug } from '../../utils/slug';

const DetailPageSummary = (props) => {
    const { data, uiStore, appStore, listMode = true, unit = false, className } = props
    const styles = useStyles()
    const { setDeveloperDetailPanelOpen, setOpenLogin } = uiStore
    const {
        name,
        address,
        price,
        propertyType,
        bedRooms,
        banners,
        bedrooms,
        carspaces,
        bathrooms,
        features,
        services,
        showTextForPrice,
        isProject,
        isLeasing,
        internalSize,
        propertyId,
        externalSize,
        totalSize,
        water,
        council,
        strata,
        aspect,
        listingId,
        assignedAgents,
    } = data.processedData

    const {
        loggedInUser
    } = appStore

    const basicInfo = [
        {
            title: 'Price',
            show: true,
            renderItem: showTextForPrice ?
                <Typography className={styles.bodyText}>
                    {showTextForPrice}
                </Typography>
                :
                <Typography className={styles.bodyText}>
                    {isProject ? 'From ' : ''}<span className={styles.hightLightText}>{AUD(price).format()}</span>
                </Typography>
        },
        {
            title: 'Bedroom',
            show: isProject,
            renderItem:
                <Typography className={styles.bodyText}>
                    {`${bedRooms} available`}
                </Typography>
        },
        {
            title: 'Structure',
            show: !isProject,
            renderItem: <PropertySettingIcons
                bedrooms={bedrooms}
                bathrooms={bathrooms}
                carspaces={carspaces}
                color={'#000000'}
                textStyle={{ marginRight: 2 }}
                iconStyle={{ marginRight: 8 }}
            />
        },
        {
            title: 'Property type',
            show: true,
            renderItem:
                <Typography className={styles.bodyText}>
                    {propertyType}
                </Typography>
        },
    ]
    const projectInfo = [
        {
            title: 'Address',
            show: true,
            singleLine: true,
            renderItem:
                <Typography className={styles.bodyText}>
                    {address.fullText}
                </Typography>
        },
        {
            title: 'Developer',
            show: isProject && data.processedData.developer,
            singleLine: true,
            renderItem:
                <Typography
                    onClick={() => {
                        setDeveloperDetailPanelOpen(true)
                    }}
                    className={styles.bodyText}
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                    {data.processedData.developer}
                </Typography>
        },
        {
            title: 'Completion date',
            show: isProject && data.processedData.completeDate,
            singleLine: true,
            renderItem:
                <Typography className={styles.bodyText}>
                    {data.processedData.completeDate}
                </Typography>
        },
        {
            title: 'Display centre',
            show: isProject && data.processedData.dispAddress,
            singleLine: true,
            renderItem:
                <Typography className={styles.bodyText}>
                    {data.processedData.dispAddress?.fullText}
                </Typography>
        },
        {
            title: 'Project website',
            show: isProject && false,
            singleLine: true,
            renderItem:
                <Typography className={styles.bodyText}>
                    {null}
                </Typography>
        },
    ]

    const propertyInfo = [
        {
            title: 'Property ID',
            show: !isProject,
            renderItem:
                <Typography className={styles.bodyText}>
                    {propertyId}
                </Typography>
        },
        {
            title: 'Internal size',
            show: !isProject && internalSize,
            renderItem:
                <Typography className={styles.bodyText}>
                    {internalSize} m²
                </Typography>
        },
        {
            title: 'External size',
            show: !isProject && externalSize,
            renderItem:
                <Typography className={styles.bodyText}>
                    {externalSize} m²
                </Typography>
        },
        {
            title: 'Total size',
            show: !isProject && totalSize,
            renderItem:
                <Typography className={styles.bodyText}>
                    {totalSize} m²
                </Typography>
        },
        {
            title: 'Water',
            show: !isProject && water,
            renderItem:
                <Typography className={styles.bodyText}>
                    {`${AUD(water).format()} / quarter`}
                </Typography>
        },
        {
            title: 'Strata',
            show: !isProject && strata,
            renderItem:
                <Typography className={styles.bodyText}>
                    {`${AUD(strata).format()} / quarter`}
                </Typography>
        },
        {
            title: 'Council',
            show: !isProject && council,
            renderItem:
                <Typography className={styles.bodyText}>
                    {`${AUD(council).format()} / quarter`}
                </Typography>
        },
        {
            title: 'Aspect',
            show: !isProject && aspect,
            renderItem:
                <Typography className={styles.bodyText}>
                    {aspect}
                </Typography>
        }
    ]


    const dataList = [
        ...basicInfo,
        ...projectInfo,
    ]

    const boxData = [
        ...basicInfo,
        ...propertyInfo,
        ...projectInfo,
    ]

    const unitData = [
        {
            title: 'Property type',
            show: true,
            renderItem:
                <Typography className={styles.bodyText}>
                    {propertyType}
                </Typography>
        },
        ...propertyInfo,
        ...projectInfo,
    ]
    console.log('processData', data.processedData);
    return (
        listMode ?
            <div className={cx(styles.root, className)}>
                <ul className={styles.ulStyle}>
                    {dataList.filter(item => item.show).map(({ title, renderItem }) => (
                        <li className={styles.listStyle} key={title}>
                            <div>
                                <Typography className={styles.title}>{title}:</Typography>
                                {renderItem}
                            </div>
                            <div className={styles.divider} />
                        </li>
                    ))}
                </ul>

                {
                    isLeasing &&
                    <Button
                        variant="contained"
                        disabled={assignedAgents.length < 1}
                        disableElevation
                        className={styles.applyButton}
                        onClick={async () => {
                            if (loggedInUser) {
                                const agent = assignedAgents[0]
                                try { 
                                    console.log(listingId, agent?.id, agent?.[":agency"]?.["id"])
                                    if (listingId && agent?.id && agent?.[":agency"]?.["id"]) {
                                        const res = await appStore.clientApi.initiateLeasingApplication_(listingId, agent.id)
                                        if (res.id) {
                                            window.open(leasingApplicationSlug(res.id, address.fullText, agent[":agency"]["id"], banners?.[0]?.placeholder), "_blank")
                                        }
                                        else {
                                            message.error("Failed to start the leasing application")
                                        }
                                    } else {
                                        console.warn(listingId, agent.id)
                                        throw new Error("Invalid listing or agency")
                                    }
                                } catch (err) {
                                    if (typeof err === 'string' && err.indexOf('Duplicate reservation') > -1) {
                                        message.error("You have already had a leasing application for this property.")
                                    } else {
                                        message.error(err?.message || "Failed to start the leasing application")
                                    }
                                }
                            } else {
                                setOpenLogin(true)
                            }
                        }}
                    >
                        Apply now
                    </Button>
                }
            </div >
            :
            <div className={cx(styles.boxRoot, className)}>
                {/* {(unit?unitData:boxData).filter(item=>item.show).map(({title,renderItem,singleLine})=>(
                <div className={styles.item} style={{width:singleLine?'100%':'50%'}} key={title}>
                    <Typography className={cx(styles.boxTitle)}>{title}:</Typography>
                    {renderItem}
                </div>
            ))} */}
                <Grid container spacing={1}>
                    {(unit ? unitData : boxData).filter(item => item.show).map(({ title, renderItem, singleLine }) => (
                        <Grid item className={styles.alignH} key={title} xs={singleLine ? 12 : 6}>
                            <Typography className={cx(styles.boxTitle)}>{title}:</Typography>
                            {renderItem}
                        </Grid>
                    ))}
                </Grid>
            </div>
    )
}

const useStyles = makeStyles(theme => ({
    boxRoot: {
        width: '100%',
        ...hBox('center'),
        flexWrap: 'wrap'
    },
    item: {
        width: "50%",
        ...hBox('center'),
        marginTop: 2,
        marginBottom: 2
    },
    boxTitle: {
        width: 150,
        ...typography.subtitle1,
        color: theme.palette.grey[500],
    },
    root: {
        position: 'relative',
        minWidth: 220,
        marginLeft: 16,
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    bodyText: {
        ...typography.body1,
        fontWeight: "500",
    },
    hightLightText: {
        color: theme.palette.clientPrimary.main,
    },
    listStyle: {
        listStyle: 'none',
        marginTop: 8,
        marginBottom: 8
    },
    ulStyle: {
        paddingLeft: 0
    },
    divider: {
        marginTop: 8,
        height: 0.5,
        width: '100%',
        backgroundColor: theme.palette.grey[300],
    },
    title: {
        ...typography.body1,
        color: theme.palette.grey[500]
    },
    alignH: {
        ...hBox('center')
    },
    applyButton: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        color: theme.palette.getContrastText(common["black"]),
        backgroundColor: common["black"],
        '&:hover': {
            backgroundColor: common["black"],
        },
    }
}))

export default compose(
    inject('uiStore', 'appStore'),
    observer
)(DetailPageSummary)
