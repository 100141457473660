import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import IconButton from '@material-ui/core/IconButton'

import Close from '@material-ui/icons/Close'
import {hBox} from "gx-web-ui/utils/flex";

import { makeStyles } from '@material-ui/core/styles';


import {Tabs} from 'antd'



const { TabPane } = Tabs;

const CustomTabItem = (props) => {




    return(
        <div></div>
    )
}

export default compose(inject('appStore','uiStore'),observer)(CustomTabItem)