import * as React from "react"

function SvgComponent(props) {
  return (
    <svg       
        width="1em"
        height="1em" 
        viewBox="0 0 121 81" {...props}>
      <path
        d="M18.2 22.8V7.7c0-1.1.9-2.1 2.1-2.1h5.3c1.1 0 2.1.9 2.1 2.1v4.5c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5V7.7c0-3.9-3.2-7.1-7.1-7.1h-5.3c-3.9 0-7.1 3.2-7.1 7.1v15.1H.2l.1 2.6c.5 19.4 10.6 35.3 29.9 47.5l-3.6 3.7c-1 1-1 2.6 0 3.5 1 .9 2.6 1 3.5 0l5.1-5.2 50.4.2 5.6 5c.5.4 1.1.6 1.7.6.7 0 1.4-.3 1.9-.8.9-1 .9-2.6-.2-3.5l-4.1-3.7c17.4-12.1 27.5-27.9 29.8-47.2l.3-2.8H18.2v.1zm67.6 47.1h-51C16.3 58.9 6.7 45.1 5.3 27.8h109.5c-2.8 17.2-12.6 31.3-29 42.1z"
      />
    </svg>
  )
}

export default SvgComponent
