
import React, { Component, useState, useEffect } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import FontAwesomeIcon from 'react-native-vector-icons/dist/FontAwesome';

const QrCodeButton = props=>{
    const {onClick,...other} = props
    return  <ButtonBase disableRipple onClick={onClick}>
                <FontAwesomeIcon name='qrcode' size={24} color='white' {...other}/>
            </ButtonBase>
}

export default QrCodeButton