import { ensureEndsWith, dropIfEndsWith } from "./lang";

export const isLocalHost = typeof window !== "undefined" && window.location.hostname === "localhost";

export function buildQuery(data) {
    return Object.keys(data)
        .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(data[k]))
        .join("&");
}

export function newTab(path) {
    let link = document && document.createElement("a");
    link.href = path;
    link.target = "_blank";
    link.click();
}

/**
 * 
 * @param {*} pathname 
 * @param {*} query 
 * @param {*} hash 
 * @param {*} [options] 
 * @property {boolean} [options.appendPath] 
 * @property {boolean} [options.mergeQuery] 
 */
export function webPath(pathname, query, hash, options) {
    let loc = typeof window !== "undefined" && window.location;
    let webPath;

    //pathname
    if (pathname == null) {
        webPath = loc.pathname;
    } else {
        if (pathname === "") {
            webPath = "/";
        } else if (pathname[0] === "/") {
            webPath = pathname;
        } else {
            webPath = "/" + pathname;
        }

        if (options && options.appendPath) {
            webPath = dropIfEndsWith(loc.pathname, "/") +  webPath;
        }
    }

    //query
    if (query == null) {
        if (options && options.mergeQuery) {
            webPath += loc.search;
        }
    } else {
        let urlParams;

        if (options && options.mergeQuery) {
            urlParams = new URLSearchParams(loc.search.substr(1));            
        } else {
            urlParams = new URLSearchParams();
        }

        for (let key in query) {
            let val = query[key];
            if (Array.isArray(val)) {
                urlParams.delete(key);
                val.forEach(v => urlParams.append(key, v));
            } else {
                urlParams.set(key, val);
            }                
        }

        let qs = urlParams.toString();
        if (qs.length > 0) {
            webPath += '?' + qs;
        }            
    }

    //hash
    if (hash != null) {
        webPath += "#" + hash;
    }

    return webPath;
}

export function url(pathname, query, hash, options) {  
    let loc = window.location;  
    return loc.protocol + "//" + loc.host + webPath(pathname, query, hash, options);
}

export function pathToBreadcrumbNodes(fullPath) {
    let path = fullPath;
    let nodes = [path];
    let lastPos = path.lastIndexOf("/");
    while (lastPos > 0) {
        path = path.substr(0, lastPos);
        nodes.push(path);
        lastPos = path.lastIndexOf("/");
    }

    return nodes;
}

export function breakPathIntoParams(fullPath, basePath) {
    if (!fullPath.startsWith(basePath)) {
        throw new Error(`Path "${fullPath}" does not start with `);
    }

    let right = fullPath.substr(basePath.length);
    if (right[0] === "/") {
        right = right.substr(1);
    }

    let parts = right.split("/");
    let key;
    let params = {};

    parts.forEach(p => {
        if (key) {
            params[key] = p;
            key = undefined;
        } else {
            key = p;
        }
    });

    return params;
}

export function putParamsIntoPath(basePath, params) {
    let right = "";
    for (let key in params) {
        let val = params[key];
        if (val == null) continue;
        right += encodeURIComponent(key) + "/" + encodeURIComponent(val);
    }

    return ensureEndsWith(basePath, "/") + right;
}
