
import React, { Component, useState, useEffect } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import PersonOutline from '@material-ui/icons/PersonOutline';
import {hBox, vBox} from "gx-web-ui/utils/flex";

import Typography from '@material-ui/core/Typography';


import { makeStyles } from '@material-ui/core/styles';
import { vAlignInHBox } from '../../utils/flex';
import googlePlayIcon from  '../../assets/icons/google-playstore.png'

const useStyles = makeStyles((theme) => ({
    root :{
        justifyContent : 'flex-start',
        paddingLeft : 36
    }
}));

const GooglePlayDownloadBtn = props=>{
    const {onClick,style={},...other} = props

    const classes = useStyles()

    return  <ButtonBase disableRipple onClick={onClick} style={{...style , 'border' : '1px solid #80808066',borderRadius : 4, height:80}}>
        <div style={{...hBox('center','flex-start'),width : "100%", paddingLeft : "18%"}}>
            <div>
                <img src={googlePlayIcon} style={{height : 36, width:36}}/>
            </div>
            <div style={{  display: 'flex',flexDirection: 'column',alignItems: 'flex-start', marginLeft : 6}}>
                <Typography variant="body2" >
                    {'Get it on'}
                </Typography>
                <Typography variant="h5" style={{fontWeight: "bold"}}>
                    {'Google Play'}
                </Typography>
            </div>
        </div>
    </ButtonBase>
}

export default GooglePlayDownloadBtn