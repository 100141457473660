import React,{useState,useEffect} from 'react'
import { useTheme,makeStyles,createStyles, withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import cx from 'classnames'
import {StyledTabs,StyledTab} from '@src/components/common/CustomTabSet'
import InputAdornment from '@material-ui/core/InputAdornment';
import {AUD} from '@src/utils/currency'
import chunk from 'lodash/chunk'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import {NSWStampDutyCalculator} from '@src/utils/calculator'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PrimaryCheckBox from '@src/components/common/PrimaryCheckBox';
import NumberFormat from 'react-number-format';

const CustomToggleButton = withStyles(theme=>({
    root:{
        textTransform:'none',
        width:'100%'
    }
})

)(ToggleButton)

const NumberFormatCustom = (props)=> {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        decimalScale={0}
      />
    );
  }

const CustomTextField = props=>{
    const {value,disabled,onChange,InputProps,style={margin:0,},selectOptions,...other} = props
    const styles = useStyles()
    return <TextField
                value={value}
                InputLabelProps={{
                    shrink: true,
                    classes:{
                        root:styles.inputs
                    }
                }}
                style={style}
                className={cx({[styles.disabledInput]:disabled})}
                disabled={disabled}
                onChange={e=>onChange(e.target.value)}
                fullWidth
                variant="outlined"
                InputProps={InputProps}
                {...other}
            >
            {selectOptions && selectOptions.map((option)=>(
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
            ))}
            </TextField>
}

const RepaymentCalculator = (props)=>{
    const {inModal=false} = props
    const [reRepayment,setEstRepayment] = useState(0)
    const [price,setPrice] = useState(props.price||0)
    const [deposit,setDeposit] = useState(props.price*0.2||0)
    const [loanTerm,setLoanTerm] = useState(30)
    const [loanAmount,setLoanAmount] = useState(props.price*0.8||0)
    const [interestRate,setInterestRate] = useState(4.19)
    const [comparisonRate,setComparisonRate] = useState(4.30)

    const styles = useStyles()
    const calculate=()=>{
        if(interestRate===0 || loanTerm===0 || deposit===0 || price===0){
            return
        }
        const monthlyTerm = loanTerm * 12
        const rate = interestRate / 100 / 12
        setEstRepayment(
            (loanAmount * Math.pow(1 + rate,monthlyTerm) * rate) / 
            (Math.pow(1 + rate,monthlyTerm) - 1)
        )
    }
    

    useEffect(() => {
        calculate()
    }, [price,deposit,loanTerm,interestRate])

    const inputs =[
        {
            title:'Property price',
            value:`${price}`,
            onChange:(value)=>{
                const numberValue = value
                if(numberValue<0){
                    setPrice(0)
                }else{
                    setPrice(numberValue)
                }
                setLoanAmount(numberValue-deposit)
            },
            props:{
                startAdornment:<InputAdornment position="start">$</InputAdornment>,
                inputComponent:NumberFormatCustom
            },
            disabled:false,
        },
        {
            title:'Deposit',
            value:`${deposit}`,
            onChange:(value)=>{
                const numberValue = value
                if(0 > numberValue){
                    setDeposit(0)
                }else{
                    setDeposit(numberValue)
                }
                setLoanAmount(price-numberValue)
            },
            props:{
                startAdornment:<InputAdornment position="start">$</InputAdornment>,
                inputComponent:NumberFormatCustom
            },
            disabled:false,
        },
        {
            title:'Loan term',
            value:`${loanTerm}`,
            onChange:(value)=>{
                if(Number(value)<0){
                    setLoanTerm(1)
                }else{
                    setLoanTerm(value)
                }
            },
            props:{
                endAdornment:<InputAdornment position="end">years</InputAdornment>,
            },
            disabled:false,
        },
        {
            title:'Interest rate',
            value:`${interestRate}`,
            onChange:(value)=>{

                if(Number(value)>100){
                    setInterestRate(100)
                }else if(Number(value)<0){
                    setInterestRate(0)
                }else{
                    setInterestRate(value)
                }
            },
            disabled:false,
        },
        {
            title:'Loan amount',
            value:`${loanAmount}`,
            onChange:(value)=>setLoanAmount(value),
            props:{
                startAdornment:<InputAdornment position="start">$</InputAdornment>,
                inputComponent:NumberFormatCustom
            },
            disabled:true,
        },
        {
            title:'Comparison rate',
            value:`${comparisonRate}`,
            onChange:(value)=>setComparisonRate(value),
            disabled:false,
        },
    ]

    return <div>
        <div className={styles.container}>
            {chunk(inputs,2).map((ck,index)=>
                <div className={styles.line} key={index}>
                    {ck.map(({title,value,onChange,disabled,props},i)=>
                        <div style={{width:'100%',marginRight:i===0?16:0}} key={title}>
                            <div className={styles.inputLabel}>{title}</div>
                            <CustomTextField 
                                value={value} 
                                onChange={onChange} 
                                disabled={disabled} 
                                InputProps={props}
                                margin={inModal?'dense':'normal'}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
        <Typography className={styles.summary}>
            Estimated repayments:<span className={styles.price}> {AUD(reRepayment).format(true)}</span> / month
        </Typography>
    </div>
}

const StampDutyCalculator = props=>{
    const {inModal=false} = props
    const [stampDuty, setStampDuty] = useState(0)
    const [price, setPrice] = useState(props.price||0)
    const [propertyUse, setPropertyUse] = useState('home')
    const [propertyType, setPropertyType] = useState('established')
    const [state, setState] = useState('NSW')
    const [firstHomeBuyer, setFirstHomeBuyer] = useState(false)
    const styles = useStyles()
    useEffect(() => {
        const {stampDutyAmount, totalDutySaving, foreignAdditional} = NSWStampDutyCalculator(price,propertyUse,propertyType,firstHomeBuyer)
        setStampDuty(stampDutyAmount)
    }, [price, propertyUse, propertyType, state, firstHomeBuyer])
    const inputs =[
        {
            title:'Property use',
            component:  <ToggleButtonGroup
                            value={propertyUse}
                            exclusive
                            size="small"
                            className={styles.buttonGroup}
                            onChange={(e,newV)=>setPropertyUse(newV)}
                        >
                            <CustomToggleButton value="home">
                                A home to live in
                            </CustomToggleButton>
                            <CustomToggleButton value="investment">
                                An investment
                            </CustomToggleButton>
                        </ToggleButtonGroup>
        },
        {
            title:'State',
            component:<CustomTextField
                    onChange={v=>setState(v)}
                    value={state}
                    disabled
                    margin='dense'
                    select
                    SelectProps={{
                        native: false,
                    }}
                    selectOptions={[
                        {
                            label:'NSW',
                            value:'NSW'
                        }
                    ]}

                />
        },
        {
            title:'Property type',
            component:  <ToggleButtonGroup
                            value={propertyType}
                            exclusive
                            size="small"
                            className={styles.buttonGroup}
                            onChange={(e,newV)=>setPropertyType(newV)}
                        >
                            <CustomToggleButton value="established">
                                Existing
                            </CustomToggleButton>
                            <CustomToggleButton value="new">
                                New
                            </CustomToggleButton>
                            <CustomToggleButton value="vacant">
                                Land
                            </CustomToggleButton>
                        </ToggleButtonGroup>
        },
        {
            title:'Property price',
            component:<CustomTextField 
                value={price} 
                onChange={(value)=>{
                    const numberValue = value
                    if(numberValue<0){
                        setPrice(0)
                    }else{
                        setPrice(numberValue)
                    }
                }} 
                margin={inModal?'dense':'normal'}
                disabled={false} 
                InputProps={{
                    startAdornment:<InputAdornment position="start">$</InputAdornment>,
                    inputComponent:NumberFormatCustom
                }}
            />
        }
    ]

    return <div>
        <div>
            {chunk(inputs,2).map((ck,index)=>
                <div className={styles.line} key={index}>
                    {ck.map(({title,component},i)=>
                        <div style={{width:'100%',marginRight:i===0?16:0}} key={title}>
                            <div className={styles.inputLabel}>{title}</div>
                            {component}
                        </div>
                    )}
                </div>
            )}
            <FormControlLabel
                control={<PrimaryCheckBox checked={firstHomeBuyer} onChange={e=>setFirstHomeBuyer(e.target.checked)} name={'first home buyer'} />}
                label={'First home buyer'}
            />
        </div>
        <Typography className={styles.summary}>
            Stamp duty: <span className={styles.price}> {AUD(stampDuty).format(true)}</span>
        </Typography>
    </div>
}


const PropertyCalculator = (props) => {
    const {className,style,data,inModal=false }= props
    const styles = useStyles()
    const [tab, setTab] = useState(0)
    const {price} = data.processedData
    const tabs = [
        {
            label:'Repayments',
            component:<RepaymentCalculator price={price||0} inModal={inModal}/>
        },
        {
            label:'Stamp duty',
            component:<StampDutyCalculator price={price||0} inModal={inModal}/>
        },
    ]
    return (
        <div className={cx(className)} style={style}>
            <Typography className={styles.title}>Calculator</Typography>
            <StyledTabs value={tab} onChange={(e,newValue)=>setTab(newValue)}>
                {tabs.map(({label})=>
                    <StyledTab key={label} label={label}/>
                )}
            </StyledTabs>
            <div className={styles.calculator}>
                {tabs[tab].component}
            </div>
        </div>
    )
}
const useStyles = makeStyles(theme=>({
    root:{
        
    },
    title:{
        fontWeight:600,
        fontSize:20
    },
    price:{
        color:theme.palette.clientPrimary.main,
        fontSize:23,
        fontWeight:500
    },
    summary:{
        marginTop:16,
        color:theme.palette.grey[500],
        fontSize:18
    },
    container:{
        // ...hBox('center'),
        // flexWrap:'wrap'
    },
    // textField:{
    //     width:'45%',
    //     margin:8
    // }
    line:{
        marginBottom:8,
        width:'100%',
        ...hBox('center')
    },
    calculator:{
        marginTop:16,
    },
    inputLabel:{
        color:theme.palette.grey[500],
        marginBottom:2,
    },
    disabledInput:{
        backgroundColor:theme.palette.grey[200],
        overflow:'hidden',
        borderRadius:4
    },
    buttonGroup:{
        width:'100%'
    },
    inputs:{
        height:40
    }
}))

export default PropertyCalculator
