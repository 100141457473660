import React,{ useState,forwardRef,useEffect } from 'react'
import compose from "recompose/compose";
import {inject,observer} from 'mobx-react'

import IconButton from '@material-ui/core/IconButton'

import { ReactComponent as ArrowLeft } from '@src/assets/svgIcons/ArrowLeft.svg'

const BackButton = (props) => {


    const {backFunction } = props


    return(<IconButton disableRipple onClick={backFunction} style = {{background : 'transparent',position :'relative', left : 0}}>
            <ArrowLeft />
        </IconButton>             
    )
}

export default compose(inject('appStore','uiStore'),observer)(BackButton)