import React, { Component, useState, forwardRef } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase';
import {ReactComponent as Task} from '@src/assets/svgIcons/Tasks 2.svg'
import {ReactComponent as TaskFilled} from '@src/assets/svgIcons/Tasks 2 filled.svg'

const NoteIconButton = (props,ref) => {
    const {hasNote,onClick,className,style,size=20} = props

    return (
        <ButtonBase disableRipple onClick={onClick} ref={ref} style={style}>
            {hasNote ?
                <TaskFilled height={size} width={size} fill={'white'} className={className}/>
                :
                <Task height={size} width={size} fill={'white'} className={className}/>
            }
        </ButtonBase>
    )
}

export default forwardRef(NoteIconButton)
