import React,{ useState,forwardRef } from 'react'

import { ReactComponent as Visibility }  from '../../assets/svgIcons/View.svg'
import { ReactComponent as VisibilityOff }  from '../../assets/svgIcons/CloseView.svg';
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';

import IconButton from '@material-ui/core/IconButton';

const VisibleButton = (props) => {


    const {showPassword,handleClickShowPassword} = props

    return(
        <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            disableRipple
            style={{ backgroundColor: 'transparent' }}
            // onMouseDown={handleMouseDownPassword}
        >
            {/* {showPassword ? <Visibility style={{'color' : 'black'}}/> : <VisibilityOff style={{'color' : 'black'}}/>} */}
            {showPassword ? <VisibilityOff /> : <Visibility/>}
        </IconButton>
    )
}

export default VisibleButton;
    