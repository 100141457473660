import React, { Component, useState, useRef } from 'react'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';
import { AUD } from '@src/utils/currency';
import join from 'lodash/join'
import { typography } from '@src/theme/theme';
import PropertyServiceAvailableCoverView from './PropertyServiceAvailableCoverView'
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconTint from 'react-icon-tint';
import { DirectionButton, QrCodeButton, FavorIconButton } from '@src/components/common'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose'
import { withResizeDetector } from 'react-resize-detector';
import Bed from '@src/components/icons/Bed'
import NoteIconButton from '@src/components/common/NoteIconButton'
import PropertyCardNote from '@src/components/property/PropertyCardNote'
import {inject,observer} from 'mobx-react'



const PropertyStatusTag = props=>{
    const {type,style} = props
    const theme = useTheme()
    const styles = useStyles()
    const status = {
        "new":{
            title:"New",
            color:theme.palette.clientPrimary.main,
        },
        'underOffer':{
            tistle:"Under offer",
            color:theme.palette.grey[500]
        }
    }
    return <div className={styles.tag} style={{backgroundColor:status[type].color,...style}}>
        <Typography variant='caption' className={styles.tagText}>{status[type].title}</Typography>
    </div>
}

const ProjectSummaryCover = props=>{
    const { 
        children, 
        project, 
        onToggleFavor, 
        favored=false,
        detail=true, 
        onPressQRCode,
        onPressDirection,
        isDetailCover=false,
        collectionDetails,
        width,
        uiStore
    } = props
    const {checkLogin} = uiStore
    const {thumbnail, name, address, price, propertyType, bedRooms, features, developer,completeDate, services, showTextForPrice } = project
    // console.log(project)
    const [bedRoomTextLength, setBedRoomTextLength] = useState(0)
    const styles = useStyles()
    const theme = useTheme()
    const [openNote, setOpenNote] = useState(false)
    const noteBtnRef = useRef(null)
    return <div className={styles.root}>
        {children}
        <div className={styles.gradient}/>
        
        {detail &&
        <React.Fragment>
        <div className={styles.gradientTop}/>
        <div className={styles.header}>
            <Avatar src={thumbnail} className={styles.image}/>
            <Typography className={styles.headerText}><strong style={{fontWeight:400}}>{name}</strong>· {address.city}</Typography>
        </div>
        </React.Fragment>
        }
        {(onToggleFavor || onPressDirection||onPressQRCode || collectionDetails) &&
            <div className={styles.saveOverlay}>
                <div className={styles.alignH}>
                    {onPressDirection && <DirectionButton onClick={onPressDirection} className={styles.iconButton} style={{ ...((onToggleFavor|| onPressQRCode) && {paddingRight:8})}}/>}
                    {onPressQRCode && <QrCodeButton onClick={onPressQRCode} className={styles.iconButton} style={{...(onToggleFavor && {paddingRight:8})}}/>}
                    {onToggleFavor && <FavorIconButton onToggleSave={onToggleFavor} className={styles.iconButton} isSaved={favored}/>}
                </div>
                {collectionDetails && 
                    <div style={{marginTop:8}}>
                        <NoteIconButton hasNote={collectionDetails.note!==null && collectionDetails.note!==''} onClick={checkLogin(()=>setOpenNote(!openNote))} className={styles.iconButton} ref={noteBtnRef}/>
                        <PropertyCardNote open={openNote} anchorRef={noteBtnRef} onClose={()=>setOpenNote(false)} collectionDetails={collectionDetails}/>
                    </div>
                }
            </div>
        }
        <div className={styles.info}>
            <div className={styles.spaceBetween}>
                <div>
                    {project.saleStatus &&  
                    <div className={styles.tagsContainer}>
                        <PropertyStatusTag type={project.saleStatus}/>
                    </div>
                    }
                    <div className={styles.alignH}>
                        <Typography variant='body1' className={styles.priceText}>
                            {showTextForPrice ? showTextForPrice : `From ${AUD(price).format(true)}`} 
                        </Typography>
                        <Typography variant='body2' className={styles.typeText}>{join(propertyType,',')}</Typography>
                    </div>
                    <div className={styles.alignH}>
                        <span className={styles.alignH} style={{marginRight:4}}>
                            <IconTint src={require('@src/images/transparentLogo.png')} color='#ffffff' maxWidth={'13'} maxHeight={'13'}/>
                        </span>
                        <Typography variant='body2' className={styles.addressText}>{address.fullText}</Typography>
                    </div>
                </div>
                <div className={styles.corner}>
                    {isDetailCover?
                    <PropertyServiceAvailableCoverView VR={services.VR} floorPlan={services.floorPlan}/>
                    :
                    <div className={styles.alignH}>
                        <Typography variant='body2' className={styles.bedRoomsText}>{bedRooms}</Typography>
                        <Bed height={16} width={16} fill={'#ffffff'} opacity={0.8}/>
                    </div>
                    }
                </div>
            </div>
            {isDetailCover &&
            <div className={styles.spaceBetween}>
                <div className={styles.alignH}>
                    <Typography variant='caption' className={styles.developerText}>By {developer} {completeDate && ' | '}</Typography>
                    {completeDate &&
                    <React.Fragment>
                        <Icon name='calendar-plus' size={10} color={'rgba(255,255,255,0.6)'} style={{paddingRight:2,paddingLeft:2}}/>
                        <Typography variant='caption' className={styles.developerText}>{completeDate}</Typography>
                    </React.Fragment>
                    }
                </div>
                <div className={styles.alignH}>
                    <Typography variant='body2' className={styles.bedRoomsText}>{bedRooms}</Typography>
                    <Bed height={16} width={16} fill={'#ffffff'} opacity={0.8}/>
                </div>
            </div>
            }
        </div>
    </div>
}

const useStyles = makeStyles((theme) => createStyles({
    root: {
        flex: 1,
        position: 'relative',
        overflow:'hidden'
    },
    header:{
        position:'absolute',
        top:8,
        left:8,
        ...hBox('center'),
        pointerEvents:'none'
    },
    image:{
        width:25,
        height:25,
        resizeMode: 'cover',
        marginRight:8,
    },
    headerText:{
        // ...typography.body1,
        fontSize:14,
        fontWeight:300,
        color:'white'
    },
    saveOverlay: {
        position: 'absolute',
        padding:12,
        top:0,
        right:0,
    },
    info:{
        position:'absolute',
        bottom:0,
        left:0,
        width:'100%',
        padding:8,
        pointerEvents:'none'
    },
    textColor:{
        color:'white'
    },
    priceText:{
        fontWeight:600,
        color: 'white',
    },
    typeText:{
        color: 'white',
        ...typography.body2,
        paddingLeft: 8,
        // fontSize:12,
    },
    addressText:{
        ...typography.body,
        color: 'white',
        fontSize:12,
        opacity:0.8
    },
    bedRoomsText:{
        // ...typography.body2,
        fontSize:12,
        color:'white',
        marginRight:4
    },
    linearGradient: {
        flex: 1,
    },
    eyeOverlayShadow:{
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        elevation:10,
        shadowOpacity: 0.7,
        shadowRadius: 3,
    },
    gradient: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(0deg, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 25%)',
        pointerEvents:'none'
    },
    gradientTop:{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(40,40,40,0.75) 0%, rgba(0,0,0,0) 20%, rgba(0,212,255,0) 100%)',
        pointerEvents:'none'
    },
    iconButton:{
        // textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)'
        filter: 'drop-shadow(0 2px 3px rgba(0, 0, 0, 1))'
    },
    chip:{
        paddingLeft:8,
        paddingRight:8,
        paddingVertical:2,
        marginRight:4
    },
    chipText:{
        fontWeight:400
    },
    centerLine:{
        ...hBox('bottom')
    },
    logo:{
        width:13,
        height:13,
        resizeMode:"contain",
        tintColor: "white",
        marginRight:4,
    },
    developerText:{
        // ...typography.caption,
        fontWeight:300,
        fontSize:11,
        color:'white',
        opacity:0.6 
    },
    paddingTop:{
        paddingTop:4
    },
    corner:{
        ...vBox('flex-end')
    },
    eyeOverlay:{
        position:'absolute',
        top:0,
        left:0,
        marginTop:0,
        paddingVertical:4,
        ...hBox('center')
    },
    viewed: {
        ...hBox('center'),
        color:'white',
        paddingLeft:8,
        paddingBottom:2
    },
    eyeIcon: {
        marginLeft: 8,
        marginRight: 2,
        color: 'white'
    },
    tag:{
        paddingLeft:6,
        paddingRight:6,
        paddingTop:1,
        paddingBottom:1,
        borderRadius:3,
        ...vBox('center','center')
    },
    tagText:{
        // ...typography.body2,
        color: 'white',
        fontWeight:300
    },
    tagsContainer:{
        ...hBox('center'),
        paddingBottom:4
    },
    alignH:{
        ...hBox('center')
    },
    icon: {
        marginRight: 4,
        tintColor:'white',
        resizeMode:'contain'
    },
    spaceBetween:{
        ...hBox('bottom','space-between'),
    }
}))

export default compose(inject('uiStore'),observer,withResizeDetector)(ProjectSummaryCover)