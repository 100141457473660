import React from "react";
import { List, Badge } from "antd";
import { format, parseISO, addMinutes } from "date-fns";
import numeral from "numeral";

const FMT_DATEITME = "dd MMM yyyy p";
const FMT_DATE = "dd MMM yyyy";
const FMT_TIME = "p";

export const renderCamelCase = (value) => {
    const result = value.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}
export const renderAddress = (address) => {
    if (typeof address !== 'object' && address !== null) return "";
    const { line1, city, postcode } = address;
    return line1 + " " + city + ", " + postcode
}
export const renderLivingTime = (time) => {
    if (typeof time !== 'object' && time !== null) return "";
    const { years, months } = time;
    return years + " year(s) " + months + " month(s) "
}
export const renderArray = (value, renderItem) =>
value && (renderItem ? value.map((element, i) => renderItem(element, i)) : value.join(", "));
export const renderUnit = (value, unitName, valueType) => (value &&
    <span>
        {renderValue(value, valueType)} {unitName}
    </span>
);
export const renderHtml = (value) => <div dangerouslySetInnerHTML={{ __html: value }} />;
export const renderBoolean = (value, boolValues = ["No", "Yes"], nullValue) => value ? boolValues[1] : (nullValue && value == null ? nullValue : boolValues[0]);
export const renderNumber = (value) => value && numeral(value).format("0,0");
export const renderCurrency = (value, symbol = "$") => value && `${symbol} ${renderNumber(value)}`;
export const renderDateTime = (value, fmt = FMT_DATEITME) => value && format(parseISO(value), fmt);
export const renderDate = (value, fmt = FMT_DATE) => value && renderDateTime(value, fmt);
export const renderTime = (value, fmt = FMT_TIME) => value && renderDateTime(value, fmt);
export const renderMinutes = (value, fmt = FMT_TIME) => value && format(addMinutes(new Date(2020, 1, 1, 0, 0), value), fmt);
export const renderMobile = (value, areaCode = "+61", isNeedAreaCode) => {
    if (!value) return null;

    areaCode[0] == "+" || (areaCode = "+" + areaCode.toString());

    if (typeof value === 'string' && value.startsWith(areaCode)) {
        value = value.substr(areaCode.length);
    }

    if (value[0] !== "+") {
        if (value[0] !== "0") {
            value = isNeedAreaCode ? "0" + value : value;
        }

        if (value[0] === "4" && value.length === 9) {
            value = value.substr(0, 3) + " " + value.substr(3, 3) + " " + value.substr(6, 3);
        }

        if (value.length === 10) {
            value = value.substr(0, 4) + " " + value.substr(4, 3) + " " + value.substr(7, 3);
        }
    }

    return value;
};

export const renderList = (value, listProps) => (value &&
    <List
        size="small"
        split={false}
        renderItem={(item) => (
            <List.Item>
                <Badge status="success" text={item} />
            </List.Item>
        )}
        {...listProps}
        dataSource={value}
    />
);

export default function renderValue(value, options, t) {
    if (!options) return value;

    switch (options.type) {
        case "array":
            return renderArray(value, options.renderItem);

        case "list":
            return renderList(value, options.listProps);

        case "unit":
            if (!options.valueType || !options.name) {
                throw new Error('"name" and "valueType" are required for renderring unit.');
            }
            return renderUnit(value, options.name, options.valueType);

        case "html":
            return renderHtml(value);

        case "boolean":
            return renderBoolean(value, options.values, options.nullValue);

        case "number":
            return renderNumber(value);

        case "currency":
            return renderCurrency(value);

        case "datetime":
            return renderDateTime(value, options.format);

        case "date":
            return renderDate(value, options.format);

        case "time":
            return renderTime(value, options.format);

        case "minutes":
            return renderMinutes(value, options.format);

        case "mobile":
            return renderMobile(value, options.areaCode);
    }

    return value;
}
